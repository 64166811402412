import api from './api'
import ToastMessageService from '../services/ToastMessageService'

const merge = require('deepmerge')
export default function vuexStore (params) {
    const API_METHODS = ['get', 'patch', 'put', 'delete']
    const MUTATION_METHODS = ['replace', 'update', 'append', 'unshift']
    const BASE_ACTIONS = ['replace', 'update', 'delete', 'appendItem', 'unshiftItem', 'deleteItem', 'replaceItem', 'updateItem', 'updateDeepItem']
    const DEFAULT_ROUTE = {
        response: 'data',
        endpoint: '',
        default: null,
        stateName: null,
        actionName: null,
        isFilter: false,
        isSort: false,
        isLoading: false,
        isPagination: false,
        isEnableMutations: true,
        isTotalCount: false,
        isUpdateWithReplace: false,
        isSaveLastRequest: false,
        method: API_METHODS[0],
        mutationStates: null,
        mutationMethod: MUTATION_METHODS[0],
        baseActions: null
    }

    const { routes, getters, states, actions, mutations } = params

    const state = {}
    const mutation = {}
    const action = {}

    if (routes) {
        for (const routeName in routes) {
            if (Object.prototype.hasOwnProperty.call(routes, routeName)) {
                const route = routes[routeName]
                const mutationMethod = route.mutationMethod || DEFAULT_ROUTE.mutationMethod
                const isLoading = route.isLoading || DEFAULT_ROUTE.isLoading
                const isPagination = route.isPagination || DEFAULT_ROUTE.isPagination
                const isFilter = route.isFilter || DEFAULT_ROUTE.isFilter
                const isSort = route.isSort || DEFAULT_ROUTE.isSort
                const isTotalCount = route.isTotalCount || DEFAULT_ROUTE.isTotalCount
                const isUpdateWithReplace = route.isUpdateWithReplace || DEFAULT_ROUTE.isUpdateWithReplace
                const isSaveLastRequest = route.isSaveLastRequest || DEFAULT_ROUTE.isSaveLastRequest
                const stateName = route.stateName || routeName
                const loadingStateName = stateName + 'Loading'
                const totalStateName = stateName + 'Total'
                const pageStateName = stateName + 'Pagination'
                const filterStateName = stateName + 'Filter'
                const filterDefaultStateName = stateName + 'FilterDefault'
                const sortStateName = stateName + 'Sort'
                const lastRequestStateName = stateName + 'LastRequest'
                const method = route.method || DEFAULT_ROUTE.method
                const baseActions = route.baseActions || DEFAULT_ROUTE.baseActions
                const mutationStates = route.mutationStates || DEFAULT_ROUTE.mutationStates

                if (route.stateName !== false) {
                    state[stateName] = Object.prototype.hasOwnProperty.call(route, 'default') ? route.default : DEFAULT_ROUTE.default
                }

                if (baseActions && baseActions instanceof Array) {
                    baseActions.forEach(baseAction => {
                        if (BASE_ACTIONS.includes(baseAction)) {
                            mutation[(stateName + '_base_mutation_' + baseAction).toUpperCase()] = (state, payload = null) => {
                                switch (baseAction) {
                                    case ('replace') :
                                        state[stateName] = payload
                                        break
                                    case ('update') :
                                        if (state[stateName] instanceof Array) {
                                            state[stateName] = [...state[stateName], ...[payload]]
                                        } else {
                                            state[stateName] = { ...state[stateName], ...payload }
                                        }
                                        break
                                    case ('delete') :
                                        state[stateName] = null
                                        break
                                    case ('appendItem') :
                                        if (payload instanceof Array) {
                                            state[stateName] = [...state[stateName], ...payload]
                                        } else if (state[stateName] instanceof Array) {
                                            state[stateName] = [...state[stateName], ...[payload]]
                                        }
                                        break
                                    case ('unshiftItem') :
                                        if (state[stateName] && state[stateName].length) {
                                            state[stateName].splice(0, 0, payload)
                                        } else {
                                            state[stateName] = [payload]
                                        }
                                        break
                                    case ('deleteItem') :
                                        if (state[stateName] instanceof Array) {
                                            const index = state[stateName].findIndex(item => item.id === payload.id)
                                            state[stateName].splice(index, 1)
                                        }
                                        break
                                    case ('replaceItem') :
                                        if (state[stateName] && state[stateName] instanceof Array) {
                                            const index = state[stateName].findIndex(item => item.id === payload.id)
                                            state[stateName][index] = payload
                                        }
                                        break

                                    case ('updateItem'):
                                        if (state[stateName] && state[stateName] instanceof Array) {
                                            const index = state[stateName].findIndex(item => item.id === payload.id)
                                            state[stateName][index] = { ...state[stateName][index], ...payload }
                                        }
                                        break

                                    case ('updateDeepItem'):
                                        if (state[stateName] && state[stateName] instanceof Array) {
                                            const index = state[stateName].findIndex(item => item.id === payload.id)
                                            state[stateName][index] = merge(state[stateName][index], payload)
                                        }
                                }
                            }
                            action[stateName + 'BaseAction' + (baseAction.charAt(0).toUpperCase() + baseAction.slice(1))] = async function (context, payload = {}) {
                                context.commit((stateName + '_base_mutation_' + baseAction).toUpperCase(), payload)
                            }
                        }
                    })
                }

                if (isPagination) {
                    mutation[pageStateName.toUpperCase()] = (state, payload) => {
                        state[pageStateName] = payload
                    }
                    action[pageStateName] = async function (context, payload) {
                        context.commit(pageStateName.toUpperCase(), payload)
                    }
                }

                if (isFilter) {
                    state[filterStateName] = null
                    state[filterDefaultStateName] = null
                    mutation[filterStateName.toUpperCase()] = (state, payload) => {
                        state[filterStateName] = payload ? { ...state[filterStateName], ...payload } : null
                    }
                    mutation[filterDefaultStateName.toUpperCase()] = (state, payload) => {
                        state[filterDefaultStateName] = payload
                    }

                    action[filterStateName] = async function (context, payload) {
                        context.commit(filterStateName.toUpperCase(), payload)
                    }
                    action[filterDefaultStateName] = async function (context, payload) {
                        context.commit(filterDefaultStateName.toUpperCase(), payload)
                    }
                }

                if (isSort) {
                    state[sortStateName] = null
                    mutation[sortStateName.toUpperCase()] = (state, payload) => {
                        state[sortStateName] = payload ? { ...state[sortStateName], ...payload } : null
                    }
                    action[sortStateName] = async function (context, payload) {
                        context.commit(sortStateName.toUpperCase(), payload)
                    }
                }

                if (isLoading) {
                    state[loadingStateName] = false
                    mutation[loadingStateName.toUpperCase()] = (state, payload) => {
                        state[loadingStateName] = payload
                    }
                }

                if (isSaveLastRequest) {
                    mutation[lastRequestStateName.toUpperCase()] = (state, payload) => {
                        state[lastRequestStateName] = payload
                    }
                }

                if (isTotalCount) {
                    mutation[(totalStateName).toUpperCase()] = (state, payload = null) => {
                        state[totalStateName] = payload
                    }
                }

                if (mutationStates) {
                    for (const mutationState in mutationStates) {
                        if (Object.prototype.hasOwnProperty.call(mutationStates, mutationState)) {
                            mutation[(mutationState + '_' + mutationStates[mutationState]).toUpperCase()] = (state, payload = null) => {
                                if (mutationStates[mutationState] === 'replaceItem') {
                                    if (state[mutationState] && state[mutationState] instanceof Array) {
                                        const index = state[mutationState].findIndex(item => item.id === payload.id)
                                        state[mutationState][index] = payload
                                    }
                                } else if (mutationStates[mutationState] === 'updateItem') {
                                    if (state[mutationState] && state[mutationState] instanceof Array) {
                                        const index = state[mutationState].findIndex(item => item.id === payload.id)
                                        const temp = [...state[mutationState]]
                                        temp[index] = { ...state[mutationState][index], ...payload }
                                        state[mutationState] = temp
                                    }
                                } else if (mutationStates[mutationState] === 'deleteItem') {
                                    state[mutationState].splice(state[mutationState].indexOf(payload), 1)
                                } else {
                                    state[mutationState] = payload
                                }
                            }
                        }
                    }
                }

                mutation[(stateName + '_' + mutationMethod).toUpperCase()] = (state, payload = null) => {
                    switch (method) {
                        case ('get') :
                        case ('post') :
                            if (mutationMethod === 'append' && state[stateName]) {
                                if (payload instanceof Array) {
                                    state[stateName] = [...state[stateName], ...payload]
                                } else if (state[stateName] instanceof Array) {
                                    state[stateName] = [...state[stateName], ...[payload]]
                                } else state[stateName] = { ...state[stateName], ...payload }
                            } else if (mutationMethod === 'unshift') {
                                if (state[stateName] && state[stateName].length) {
                                    state[stateName].splice(0, 0, payload)
                                } else {
                                    state[stateName] = [payload]
                                }
                            } else if (mutationMethod === 'update') {
                                state[stateName] = { ...state[stateName], ...payload }
                            } else {
                                state[stateName] = payload
                            }
                            break

                        case ('delete') :
                        case ('patch') :
                        case ('put') :
                            state[stateName] = payload
                            break
                    }
                }

                action[route.actionName || routeName] = async function (context, payload = {}) {
                    try {
                        if (isLoading) {
                            context.commit(loadingStateName.toUpperCase(), true)
                        }
                        if (isSaveLastRequest) {
                            context.commit(lastRequestStateName.toUpperCase(), payload)
                        }

                        let url = route.endpoint
                        const filter = isFilter && context.state[filterStateName] ? context.state[filterStateName] : null
                        const sort = isSort && context.state[sortStateName] ? context.state[sortStateName] : null
                        const page = isPagination && context.state[pageStateName] ? context.state[pageStateName] : null
                        const _params = payload._params ? { ...payload._params } : null
                        const _query = payload._query ? { ...payload._query, ...filter, ...sort, ...page } : { ...filter, ...sort, ...page }
                        const _data = payload._data ? (payload._data instanceof FormData ? payload._data : JSON.parse(JSON.stringify(payload._data))) : null
                        const _headers = payload.headers ? { ...payload._headers } : null

                        if (_params) {
                            for (const key in _params) {
                                if (Object.prototype.hasOwnProperty.call(_params, key)) {
                                    url = url.replace(`{${key}}`, _params[key])
                                }
                            }
                        }

                        let response = null
                        const axiosParams = {
                            url: url,
                            method: method,
                            withCredentials: true,
                            ..._query ? { params: _query } : null,
                            ..._data ? { data: _data } : null,
                            ..._headers ? { headers: _headers } : null
                        }

                        if (!isUpdateWithReplace) {
                            context.commit((stateName + '_' + mutationMethod).toUpperCase(), _data)
                        }
                        if (route.endpoint) {
                            const { data, headers } = await api(axiosParams)
                            if (isTotalCount && headers['x-total-count']) {
                                context.commit((totalStateName).toUpperCase(), parseInt(headers['x-total-count']) || 0)
                            }
                            const responseName = route.response || DEFAULT_ROUTE.response
                            response = data[responseName] ? data[responseName] : data
                        } else {
                            response = _data
                        }
                        context.commit((stateName + '_' + mutationMethod).toUpperCase(), response)
                        if (mutationStates) {
                            for (const mutationState in mutationStates) {
                                if (Object.prototype.hasOwnProperty.call(mutationStates, mutationState)) {
                                    context.commit((mutationState + '_' + mutationStates[mutationState]).toUpperCase(), response)
                                }
                            }
                        }
                    } catch (exception) {
                        if (exception.response) {
                            const toastMessageService = new ToastMessageService()
                            switch (exception.response.status) {
                                case (500) :
                                    await toastMessageService.add(toastMessageService.HTTP_500)
                                    break
                                case (422) :
                                    await toastMessageService.add(toastMessageService.HTTP_422)
                                    break
                                case (404) :
                                    await toastMessageService.add(toastMessageService.HTTP_404)
                                    break
                            }
                        }

                        throw exception
                    } finally {
                        if (isLoading) {
                            context.commit(loadingStateName.toUpperCase(), false)
                        }
                    }
                }
            }
        }
    }
    return {
        namespaced: true,
        state: () => ({ ...state, ...states }),
        mutations: { ...mutation, ...mutations },
        actions: { ...action, ...actions },
        getters
    }
}

import api from './api'
import Echo from 'laravel-echo'

export const echoInit = () => new Echo({
    broadcaster: 'socket.io',
    client: require('socket.io-client'),
    transports: ['websocket', 'polling', 'flashsocket'],
    encrypted: true,
    host: process.env.VUE_APP_LARAVEL_ECHO_SERVER_HOST + ':' + process.env.VUE_APP_LARAVEL_ECHO_SERVER_PORT,
    auth: {
        headers: {
            Authorization: api.defaults.headers.common.Authorization
        }
    }
})

import { store } from '../store'

export default class WidgetService {
    async getWidgets (board, params) {
        return await store.dispatch('widget/getWidgets', {
            _params: {
                boardId: board
            },
            _data: params
        })
    }

    async resetWidgets () {
        return await store.dispatch('widget/resetWidgets')
    }

    async showWidget (board, widget) {
        return await store.dispatch('widget/showWidget', {
            _params: {
                boardId: board,
                widgetId: widget
            }
        })
    }

    async updateWidget (board, widget, data) {
        return await store.dispatch('widget/updateWidget', {
            _params: {
                boardId: board,
                widgetId: widget
            },
            _data: data
        })
    }

    async storeWidget (board, data) {
        return await store.dispatch('widget/storeWidget', {
            _params: {
                boardId: board
            },
            _data: data
        })
    }

    async deleteWidget (board, widget) {
        await store.dispatch('widget/getWidgetsBaseActionDeleteItem', { id: widget })
        return await store.dispatch('widget/deleteWidget', {
            _params: {
                boardId: board,
                widgetId: widget
            }
        })
    }

    async updatePosition (board, widget, position) {
        await store.dispatch('widget/updatePosition', {
            _params: {
                boardId: board,
                widgetId: widget
            },
            _data: position
        })
        return await this.updateDeepWidgetFromList({
            id: widget,
            params: {
                position: position
            }
        })
    }

    async updateDeepWidgetFromList (data) {
        return await store.dispatch('widget/getWidgetsBaseActionUpdateDeepItem', data)
    }

    async updateWidgetFromList (data) {
        return await store.dispatch('widget/getWidgetsBaseActionUpdateItem', data)
    }

    async modalToggle (state) {
        return await store.dispatch('widget/setModalState', state)
    }

    boardsLimit () {
        return process.env.VUE_APP_WIDGETS_LIMIT_PER_BOARD ?? 10
    }
}

import vuexStore from '../../core/vuexStore'

export const { state, getters, mutations, actions, namespaced } = vuexStore({
    routes: {
        register: {
            method: 'post',
            isLoading: true,
            endpoint: '/auth/register'
        },
        login: {
            method: 'post',
            isLoading: true,
            endpoint: '/auth/login'
        },
        forgot: {
            method: 'post',
            isLoading: true,
            endpoint: '/auth/forgot-password'
        },
        resetPassword: {
            method: 'post',
            isLoading: true,
            endpoint: '/auth/reset-password'
        },
        logout: {
            method: 'delete',
            isLoading: true,
            endpoint: '/auth/logout'
        },
        user: {
            isLoading: true,
            isUpdateWithReplace: true,
            endpoint: '/user'
        },
        enableTwoFactorAuthentication: {
            isLoading: true,
            isUpdateWithReplace: true,
            method: 'post',
            endpoint: '/user/two-factor-authentication'
        },
        disableTwoFactorAuthentication: {
            isLoading: true,
            isUpdateWithReplace: true,
            method: 'delete',
            endpoint: '/user/two-factor-authentication'
        },
        validateTwoFactorAuthentication: {
            isLoading: true,
            isUpdateWithReplace: true,
            method: 'post',
            endpoint: '/user/two-factor-confirm'
        },
        qrCodeTwoFactorAuthentication: {
            isLoading: true,
            isUpdateWithReplace: true,
            endpoint: '/user/two-factor-qr-code'
        }
    },
    getters: {
        loggedIn: state => !!state.user
    },
    mutations: {
        SET_USER: (state, payload) => {
            state.user = payload
        }
    },
    actions: {
        setUser: async (context, payload) => {
            context.commit('SET_USER', payload)
        }
    }
})

<template>
    <div class="layout-topbar">
        <div class="topbar-left">
            <a tabindex="0" class="menu-button" @click="onMenuButtonClick">
                <i class="pi pi-chevron-left"></i>
            </a>
            <span class="topbar-separator"></span>

            <div class="layout-breadcrumb viewname" style="text-transform: uppercase">
                <template v-if="$route.meta.breadcrumb">
                    <span>{{ $route.meta.breadcrumb[0].label }}</span>
                </template>
            </div>

            <img id="logo-mobile" class="mobile-logo" src="/assets/layout/images/logo-dark.svg" alt="diamond-layout"/>
        </div>

        <div class="topbar-right">
            <ul class="topbar-menu">
                <!--                <li class="search-item">-->
                <!--                    <a tabindex="0" @click="onSearchClick">-->
                <!--                        <i class="pi pi-search"></i>-->
                <!--                    </a>-->
                <!--                </li>-->
                <li class="profile-item flex align-items-center">
                    <i
                        v-tooltip.bottom="'Баланс для выбранных ключей'"
                        class="pi pi-question-circle mr-2"/>

                    <Tag
                        v-tooltip.bottom="'Фьючерсный баланс'"
                        @click="toggleFuturesBalanceOverlay"
                        aria:haspopup="true"
                        severity="primary"
                        class="mr-1 total-balance"
                    >
                        F: {{ futuresTotalBalance ? futuresTotalBalance.symbol : 'USDT' }}
                        <span v-if="futuresTotalBalance && (futuresTotalBalance.balance >= 0)"
                              class="ml-2">{{ futuresTotalBalance.balance }}</span>
                        <i v-else
                           class="pi pi-spin pi-spinner ml-2"></i>
                    </Tag>
                    <OverlayPanel
                        ref="futuresBalanceOverlay"
                        appendTo="body"
                        id="overlay_panel_futures_balance"
                        style="width: 350px"
                        :showCloseIcon="true"
                        :breakpoints="{'960px': '75vw'}"
                    >
                        <div class="p-fluid">
                            <div class="field">
                                <label for="futuresTotalBalanceApiKeys">
                                    Ключи общего баланса по фьючерсам
                                </label>
                                <MultiSelect
                                    id="futuresTotalBalanceApiKeys"
                                    v-model="totalBalanceSettingsForm.fields.futuresTotalBalanceApiKeys"
                                    :options="apiKeysOptions ? apiKeysOptions : []"
                                    :loading="apiKeysOptionsLoading"
                                    :disabled="apiKeysOptionsLoading"
                                    dataKey="id"
                                    optionValue="id"
                                    optionLabel="note"
                                    show-clear
                                    display="chip"
                                    :area-invalid="totalBalanceSettingsForm.errors.futuresTotalBalanceApiKeys ? true : null"
                                    :aria-describedby="totalBalanceSettingsForm.errors.futuresTotalBalanceApiKeys ? totalBalanceSettingsForm.errors.futuresTotalBalanceApiKeys : null"
                                    :placeholder="apiKeysOptionsLoading ? 'Загрузка ключей...' : 'Выбрать ключ'">
                                    <template #option="slotProps">
                                        <div class="multiselect-car-option">
                                            <span> <i class="pi pi-star-fill"
                                                      :style="`color:${slotProps.option.color ? slotProps.option.color : defaultColor}`"/>
                                                {{ slotProps.option.note }}</span>
                                        </div>
                                    </template>
                                </MultiSelect>
                                <div v-if="totalBalanceSettingsForm.errors.futuresTotalBalanceApiKeys"
                                     class="p-error text-left">
                                    <small>{{ totalBalanceSettingsForm.errors.futuresTotalBalanceApiKeys }}</small>
                                </div>
                            </div>
                            <div class="field">
                                <Button
                                    @click="updateUserTotalBalanceSettings"
                                    :loading="updateSettingsLoading"
                                    label="Сохранить"/>
                            </div>
                        </div>
                    </OverlayPanel>

                    <Tag
                        v-tooltip.bottom="'Спотовый баланс'"
                        @click="toggleSpotBalanceOverlay"
                        severity="primary"
                        class="mr-1 total-balance"
                    >
                        S: {{ spotTotalBalance ? spotTotalBalance.symbol : 'USDT' }}
                        <span v-if="spotTotalBalance && (spotTotalBalance.balance >= 0)"
                              class="ml-2">{{ spotTotalBalance.balance }}</span>
                        <i v-else
                           class="pi pi-spin pi-spinner ml-2"></i>
                    </Tag>
                    <OverlayPanel
                        ref="spotBalanceOverlay"
                        appendTo="body"
                        id="overlay_panel_futures_spot"
                        style="width: 350px"
                        :showCloseIcon="true"
                        :breakpoints="{'960px': '75vw'}"
                    >
                        <div class="p-fluid">
                            <div class="field">
                                <label for="spotTotalBalanceApiKeys">
                                    Ключи общего баланса по спотам
                                </label>
                                <MultiSelect
                                    id="spotTotalBalanceApiKeys"
                                    v-model="totalBalanceSettingsForm.fields.spotTotalBalanceApiKeys"
                                    :options="apiKeysOptions ? apiKeysOptions : []"
                                    :loading="apiKeysOptionsLoading"
                                    :disabled="apiKeysOptionsLoading"
                                    dataKey="id"
                                    optionValue="id"
                                    optionLabel="note"
                                    show-clear
                                    display="chip"
                                    :area-invalid="totalBalanceSettingsForm.errors.spotTotalBalanceApiKeys ? true : null"
                                    :aria-describedby="totalBalanceSettingsForm.errors.spotTotalBalanceApiKeys ? totalBalanceSettingsForm.errors.spotTotalBalanceApiKeys : null"
                                    :placeholder="apiKeysOptionsLoading ? 'Загрузка ключей...' : 'Выбрать ключ'">
                                    <template #option="slotProps">
                                        <div class="p-multiselect-car-option">
                                            <span> <i class="pi pi-star-fill"
                                                      :style="`color:${slotProps.option.color ? slotProps.option.color : defaultColor}`"/>
                                                {{ slotProps.option.note }}</span>
                                        </div>
                                    </template>
                                </MultiSelect>
                                <div v-if="totalBalanceSettingsForm.errors.spotTotalBalanceApiKeys"
                                     class="p-error text-left">
                                    <small>{{ totalBalanceSettingsForm.errors.spotTotalBalanceApiKeys }}</small></div>
                            </div>
                            <div class="field">
                                <Button
                                    @click="updateUserTotalBalanceSettings"
                                    :loading="updateSettingsLoading"
                                    label="Сохранить"/>
                            </div>
                        </div>
                    </OverlayPanel>
                </li>
                <li class="notifications-item" :class="{ 'active-menuitem ': topbarNotificationMenuActive }">
                    <a href="#" tabindex="0" @click="onTopbarNotificationMenuButtonClick">
                        <i class="pi pi-bell"></i>
                        <span
                            v-if="!userIsTwoFactorAuthenticationEnabled"
                            class="topbar-badge"></span>
                    </a>
                    <ul class="notifications-menu fade-in-up">
                        <template v-if="userIsTwoFactorAuthenticationEnabled">
                            <li role="menuitem">
                                У Вас нет новых уведомлений
                            </li>
                        </template>
                        <template v-else>
                            <li role="menuitem">
                                <router-link :to="{name : 'profile'}">
                                    <i class="pi pi-exclamation-triangle"></i>
                                    <div class="notification-item">
                                        <div class="notification-summary">Двухфакторная защита</div>
                                        <div class="notification-detail">Отключена</div>
                                    </div>
                                </router-link>
                            </li>
                        </template>
                    </ul>
                </li>
                <li class="profile-item" :class="{ 'active-menuitem fadeInDown': topbarUserMenuActive }">
                    <a href="#" @click="onTopbarUserMenuButtonClick">
                        <!--                        <img src="assets/demo/images/avatar/profile.jpg" alt="diamond-layout" class="profile-image" />-->
                        <Avatar icon="pi pi-user" class="mr-2"/>
                        <span class="profile-name">{{ userName }}</span>
                    </a>
                    <ul class="profile-menu fade-in-up">
                        <li>
                            <router-link :to="{name : 'profile'}">
                                <i class="pi pi-user"></i>
                                <span>Профиль</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{name : 'api-keys'}">
                                <i class="pi pi-key"></i>
                                <span>API ключи</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{name : 'orders'}">
                                <i class="pi pi-chart-bar"></i>
                                <span>Сделки</span>
                            </router-link>
                        </li>
                        <li>
                            <Divider />
                        </li>
                        <li>
                            <a href="#" @click.prevent="logout">
                                <i class="pi pi-power-off"></i>
                                <span>Выход</span>
                            </a>
                        </li>
                    </ul>
                </li>

                <!--                <li class="right-sidebar-item">-->
                <!--                    <a href="#" tabindex="0" @click="onRightMenuClick">-->
                <!--                        <i class="pi pi-align-right"></i>-->
                <!--                    </a>-->
                <!--                </li>-->
            </ul>
        </div>
    </div>
</template>

<script>
import AuthService from '../services/AuthService'
import UserService from '../services/UserService'
import FormService from '../services/FormService'
import ApiKeyService from '../services/ApiKeyService'
import ToastMessageService from '../services/ToastMessageService'
import { store } from '../store'
import { computed, ref, reactive, watch } from 'vue'

export default {
    name: 'AppTopbar',
    emits: ['menu-button-click', 'search-click', 'topbar-notification', 'topbar-user-menu', 'right-menubutton-click'],
    props: {
        topbarNotificationMenuActive: Boolean,
        topbarUserMenuActive: Boolean
    },
    setup () {
        const apiKeyService = new ApiKeyService()
        const authService = new AuthService()
        const userService = new UserService()
        const formService = new FormService()
        const toastMessageService = new ToastMessageService()

        const profile = computed(() => store.state.user.getProfile)
        const userName = computed(() => store.state.auth.user ? store.state.auth.user.name : 'none')
        const futuresTotalBalance = computed(() => store.state.user.getProfile ? store.state.user.getProfile.futuresTotalBalance : [])
        const spotTotalBalance = computed(() => store.state.user.getProfile ? store.state.user.getProfile.spotTotalBalance : [])
        const userIsTwoFactorAuthenticationEnabled = computed(() => store.state.user.getProfile ? store.state.user.getProfile.two_factor_confirmed : true)
        const apiKeysOptions = computed(() => store.state.apiKey.getApiKeys)
        const apiKeysOptionsLoading = computed(() => store.state.apiKey.getApiKeysLoading)
        const updateSettingsLoading = computed(() => store.state.user.updateSettingsLoading)
        const defaultColor = apiKeyService.defaultColor()

        apiKeyService.getApiKeys()

        const futuresBalanceOverlay = ref()
        const spotBalanceOverlay = ref()
        const toggleFuturesBalanceOverlay = ($event) => {
            futuresBalanceOverlay.value.toggle($event)
        }
        const toggleSpotBalanceOverlay = ($event) => {
            spotBalanceOverlay.value.toggle($event)
        }

        const defaultTotalBalanceSettingsForm = () => {
            return {
                fields: {
                    futuresTotalBalanceApiKeys: null,
                    spotTotalBalanceApiKeys: true
                },
                errors: {
                    futuresTotalBalanceApiKeys: null,
                    spotTotalBalanceApiKeys: null

                }
            }
        }
        const totalBalanceSettingsForm = reactive(defaultTotalBalanceSettingsForm())

        watch(profile, (state, prevState) => {
            if (state) {
                totalBalanceSettingsForm.fields.futuresTotalBalanceApiKeys = JSON.parse(JSON.stringify(state.settings.futuresTotalBalanceApiKeys ?? []))
                totalBalanceSettingsForm.fields.spotTotalBalanceApiKeys = JSON.parse(JSON.stringify(state.settings.spotTotalBalanceApiKeys ?? []))
            }
        })

        const updateUserTotalBalanceSettings = async () => {
            try {
                await userService.updateSettings(totalBalanceSettingsForm.fields)
                await toastMessageService.add({
                    detail: 'Настройки сохранены'
                })
                futuresBalanceOverlay.value.hide()
                spotBalanceOverlay.value.hide()
                await userService.getProfile()
            } catch (exception) {
                formService.fillErrors(exception, totalBalanceSettingsForm)
            }
        }

        const logout = () => authService.logout()

        return {
            userName,
            userIsTwoFactorAuthenticationEnabled,
            futuresTotalBalance,
            spotTotalBalance,
            toggleFuturesBalanceOverlay,
            toggleSpotBalanceOverlay,
            futuresBalanceOverlay,
            spotBalanceOverlay,
            totalBalanceSettingsForm,
            apiKeysOptions,
            apiKeysOptionsLoading,
            defaultColor,
            updateUserTotalBalanceSettings,
            updateSettingsLoading,
            logout
        }
    },
    data () {
        return {
            items: []
        }
    },
    unmounted () {
        if (this.subscription) {
            this.subscription.unsubscribe()
        }
    },

    methods: {
        onMenuButtonClick (event) {
            this.$emit('menu-button-click', event)
        },
        onSearchClick (event) {
            this.$emit('search-click', event)
        },
        onTopbarNotificationMenuButtonClick (event) {
            this.$emit('topbar-notification', event)
        },
        onTopbarUserMenuButtonClick (event) {
            this.$emit('topbar-user-menu', event)
        },
        onRightMenuClick (event) {
            this.$emit('right-menubutton-click', event)
        }
    }
}
</script>

<style lang="scss">
.layout-topbar {
    .total-balance {
        cursor: pointer
    }
}
</style>

<template>
    <div class="layout-footer">
        <div class="footer-logo-container">
            <img id="footer-logo" src="/assets/layout/images/logo-dark.svg" alt="diamond-layout" />
            <span class="app-name">XAIRO TRADE</span>
        </div>
        <span class="copyright">&#169; XairoTrade - {{ new Date().getFullYear()}}</span>
    </div>
</template>

<script>
export default {
    name: 'AppFooter'
}
</script>

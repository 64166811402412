<template>
    <div :class="containerClass" :data-theme="colorScheme" @click="onDocumentClick($event)">
        <ToastWrapper/>
        <BoardModal/>
        <div class="layout-content-wrapper">
            <AppTopBar :topbarNotificationMenuActive="topBarNotificationMenuActive"
                       :topbarUserMenuActive="topBarUserMenuActive" @menu-button-click="onMenuButtonClick"
                       @search-click="toggleSearch"
                       @topbar-notification="onTopBarNotificationMenuButtonClick"
                       @topbar-user-menu="onTopBarUserMenuButtonClick" @right-menu-click="onRightMenuButtonClick"
                       @right-menubutton-click="onRightMenuButtonClick"></AppTopBar>

            <div class="layout-content">
                <router-view/>
            </div>
            <AppFooter/>
        </div>

        <AppMenu :model="computedMenu" :layoutMode="layoutMode" :active="menuActive"
                 :mobileMenuActive="staticMenuMobileActive"
                 @menu-click="onMenuClick" @menuitem-click="onMenuItemClick"
                 @root-menuitem-click="onRootMenuItemClick"></AppMenu>

        <!--        <AppRightMenu :rightMenuActive="rightMenuActive" @right-menu-click="onRightMenuClick"></AppRightMenu>-->

        <!--        <AppConfig v-model:configActive="configActive" v-model:layoutMode="layoutMode" v-model:menuTheme="menuTheme" v-model:colorScheme="colorScheme" @config-click="onConfigClick" @config-button-click="onConfigButtonClick"></AppConfig>-->

        <!--        <AppSearch :searchActive="searchActive" @search-click="onSearchClick" @search-hide="onSearchHide"/>-->

        <div class="layout-mask modal-in"></div>
    </div>
</template>

<script>
import EventBus from '../event-bus'
import AppTopBar from './AppTopbar'
import AppFooter from './AppFooter'
// import AppConfig from './AppConfig'
import AppMenu from './AppMenu'
import BoardService from '../services/BoardService'
import { ref, reactive, computed, getCurrentInstance, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import UserService from '../services/UserService'
import WidgetService from '../services/WidgetService'

export default {
    components: {
        AppTopBar,
        AppFooter,
        // AppConfig,
        AppMenu
    },
    setup () {
        const app = getCurrentInstance()
        const userService = new UserService()
        const widgetService = new WidgetService()
        const route = useRoute()
        const $appState = app.appContext.config.globalProperties.$appState
        const $primevue = app.appContext.config.globalProperties.$primevue
        const $toast = app.appContext.config.globalProperties.$toast
        const $echo = app.appContext.config.globalProperties.$echo
        const store = useStore()
        const menuActive = ref(false)
        const layoutMode = ref('overlay')
        const colorScheme = ref('dim')
        const menuTheme = ref('layout-sidebar-orange')
        const overlayMenuActive = ref(false)
        const staticMenuDesktopInactive = ref(false)
        const staticMenuMobileActive = ref(false)
        const menuClick = ref(false)
        const searchActive = ref(false)
        const searchClick = ref(false)
        const userMenuClick = ref(false)
        const topBarUserMenuActive = ref(false)
        const notificationMenuClick = ref(false)
        const topBarNotificationMenuActive = ref(false)
        const rightMenuClick = ref(false)
        const rightMenuActive = ref(false)
        const configActive = ref(false)
        const configClick = ref(false)
        const menu = reactive([
            {
                label: 'Информация',
                icon: 'pi pi-cog',
                items: [
                    { label: 'Профиль', to: '/profile', icon: 'pi pi-user' },
                    { label: 'API ключи', to: '/api-keys', icon: 'pi pi-key' },
                    { label: 'Сделки', to: '/orders', icon: 'pi pi-chart-bar' }
                ]
            }
        ])
        const boards = computed(() => store.state.board.getBoards)
        const computedBoards = computed(() => {
            return boards.value ? boards.value.map((board) => {
                return {
                    label: board.title,
                    to: `/boards/${board.id}`
                }
            }) : []
        })
        const userHash = computed(() => store.state.user.getProfile.user_hash)
        userService.getProfile().then(() => {
            $echo.private('user.' + userHash.value).listen('.WidgetProceeded', ({ widget }) => {
                widgetService.updateWidgetFromList({
                    id: widget.id,
                    result: widget.result
                })
            }).listen('.UserFuturesTotalBalanceProceeded', ({ user }) => {
                userService.updateUserFuturesTotalBalance(user.futuresTotalBalance)
            }).listen('.UserSpotTotalBalanceProceeded', ({ user }) => {
                userService.updateUserSpotTotalBalance(user.spotTotalBalance)
            })
        })
        const addMenuBoard = {
            label: 'Добавить новый',
            icon: 'pi pi-plus',
            command: (event) => {
                boardService.modalToggle('create')
            }
        }

        const computedMenu = computed(() => {
            const boardsMenu = {
                label: 'Мои отчеты',
                icon: 'pi pi-th-large',
                items: []
            }
            if (Array.isArray(computedBoards.value) && computedBoards.value.length) {
                boardsMenu.items = [...computedBoards.value, ...boardsMenu.items]
                if (computedBoards.value.length < boardService.boardsLimit()) {
                    boardsMenu.items.push(addMenuBoard)
                }
            } else {
                boardsMenu.items.push(addMenuBoard)
            }
            return [...menu, { separator: true }, boardsMenu]
        })

        const containerClass = computed(() => [
            'layout-wrapper',
            {
                'layout-overlay': layoutMode.value === 'overlay',
                'layout-static': layoutMode.value === 'static',
                'layout-slim': layoutMode.value === 'slim',
                'layout-sidebar-dim': colorScheme.value === 'dim',
                'layout-sidebar-dark': colorScheme.value === 'dark',
                'layout-overlay-active': overlayMenuActive.value,
                'layout-mobile-active': staticMenuMobileActive.value,
                'layout-static-inactive': staticMenuDesktopInactive.value && layoutMode.value === 'static',
                'p-input-filled': $appState.inputStyle === 'filled',
                'p-ripple-disabled': !$primevue.config.ripple
            },
            colorScheme.value === 'light' ? menuTheme.value : ''
        ])

        const boardService = new BoardService()
        boardService.getBoards()

        watch(route, (state, prevState) => {
            menuActive.value = false
            $toast.removeAllGroups()
        })

        const onMenuClick = () => {
            menuClick.value = true
        }
        const onMenuButtonClick = (event) => {
            menuClick.value = true
            topBarUserMenuActive.value = false
            topBarNotificationMenuActive.value = false
            rightMenuActive.value = false

            if (isOverlay()) {
                overlayMenuActive.value = !overlayMenuActive.value
            }

            if (isDesktop()) {
                staticMenuDesktopInactive.value = !staticMenuDesktopInactive.value
            } else {
                staticMenuMobileActive.value = !staticMenuMobileActive.value
                if (staticMenuMobileActive.value) {
                    blockBodyScroll()
                } else {
                    unblockBodyScroll()
                }
            }
            event.preventDefault()
        }
        const onMenuItemClick = (event) => {
            if (!event.item.items) {
                EventBus.emit('reset-active-index')
                hideOverlayMenu()
            }
            if (!event.item.items && isSlim()) {
                menuActive.value = false
            }
        }
        const onRootMenuItemClick = () => {
            menuActive.value = !menuActive.value
        }
        const onTopBarUserMenuButtonClick = (event) => {
            userMenuClick.value = true
            topBarUserMenuActive.value = !topBarUserMenuActive.value

            hideOverlayMenu()

            event.preventDefault()
        }
        const onTopBarNotificationMenuButtonClick = (event) => {
            notificationMenuClick.value = true
            topBarNotificationMenuActive.value = !topBarNotificationMenuActive.value

            hideOverlayMenu()

            event.preventDefault()
        }
        const toggleSearch = () => {
            searchActive.value = !searchActive.value
            searchClick.value = true
        }
        const onSearchClick = () => {
            searchClick.value = true
        }
        const onSearchHide = () => {
            searchActive.value = false
            searchClick.value = false
        }
        const onRightMenuClick = () => {
            rightMenuClick.value = true
        }
        const onRightMenuButtonClick = (event) => {
            rightMenuClick.value = true
            rightMenuActive.value = !rightMenuActive.value
            hideOverlayMenu()
            event.preventDefault()
        }
        const onConfigClick = () => {
            configClick.value = true
        }
        const onConfigButtonClick = () => {
            configActive.value = !configActive.value
            configClick.value = true
        }
        const hideOverlayMenu = () => {
            overlayMenuActive.value = false
            staticMenuMobileActive.value = false
            unblockBodyScroll()
        }
        const blockBodyScroll = () => {
            if (document.body.classList) {
                document.body.classList.add('blocked-scroll')
            } else {
                document.body.className += ' blocked-scroll'
            }
        }
        const unblockBodyScroll = () => {
            if (document.body.classList) {
                document.body.classList.remove('blocked-scroll')
            } else {
                document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                    'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ')
            }
        }
        const isSlim = () => {
            return layoutMode.value === 'slim'
        }
        const isOverlay = () => {
            return layoutMode.value === 'overlay'
        }
        const isDesktop = () => {
            return window.innerWidth > 991
        }
        const onDocumentClick = () => {
            if (!searchClick.value && searchActive.value) {
                onSearchHide()
            }

            if (!userMenuClick.value) {
                topBarUserMenuActive.value = false
            }

            if (!notificationMenuClick.value) {
                topBarNotificationMenuActive.value = false
            }

            if (!rightMenuClick.value) {
                rightMenuActive.value = false
            }

            if (!menuClick.value) {
                if (isSlim()) {
                    EventBus.emit('reset-active-index')
                    menuActive.value = false
                }

                if (overlayMenuActive.value || staticMenuMobileActive.value) {
                    hideOverlayMenu()
                }

                unblockBodyScroll()
            }

            if (configActive.value && !configClick.value) {
                configActive.value = false
            }

            searchClick.value = false
            configClick.value = false
            userMenuClick.value = false
            rightMenuClick.value = false
            notificationMenuClick.value = false
            menuClick.value = false
        }

        return {
            menuActive,
            layoutMode,
            colorScheme,
            menuTheme,
            overlayMenuActive,
            staticMenuDesktopInactive,
            staticMenuMobileActive,
            menuClick,
            searchActive,
            searchClick,
            userMenuClick,
            topBarUserMenuActive,
            notificationMenuClick,
            topBarNotificationMenuActive,
            rightMenuClick,
            rightMenuActive,
            configActive,
            configClick,
            containerClass,
            onDocumentClick,
            onRootMenuItemClick,
            onConfigButtonClick,
            onRightMenuButtonClick,
            onConfigClick,
            onRightMenuClick,
            onMenuClick,
            toggleSearch,
            onSearchClick,
            onTopBarUserMenuButtonClick,
            onMenuItemClick,
            onTopBarNotificationMenuButtonClick,
            onMenuButtonClick,
            computedMenu
        }
    }
}
</script>

<style lang="scss">
@import "./sass/App.scss";
</style>

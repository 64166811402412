import { createApp, h, reactive } from 'vue'
import { store } from './store'
import { echoInit } from './core/echo'
import localeTemplate from './locale/template'
import moment from 'moment'
import VueGridLayout from 'vue-grid-layout'

import router from './router/index'
import PrimeVue from 'primevue/config'
// import AutoComplete from 'primevue/autocomplete'
// import Accordion from 'primevue/accordion'
// import AccordionTab from 'primevue/accordiontab'
import Avatar from 'primevue/avatar'
// import AvatarGroup from 'primevue/avatargroup'
import Badge from 'primevue/badge'
import BadgeDirective from 'primevue/badgedirective'
import Button from 'primevue/button'
// import Breadcrumb from 'primevue/breadcrumb'
import BlockUI from 'primevue/blockui'
import Calendar from 'primevue/calendar'
import Card from 'primevue/card'
// import Carousel from 'primevue/carousel'
import Chart from 'primevue/chart'
import Checkbox from 'primevue/checkbox'
import Chip from 'primevue/chip'
import Chips from 'primevue/chips'
import ColorPicker from 'primevue/colorpicker'
import Column from 'primevue/column'
// import ConfirmDialog from 'primevue/confirmdialog'
import ConfirmPopup from 'primevue/confirmpopup'
import ConfirmationService from 'primevue/confirmationservice'
// import ContextMenu from 'primevue/contextmenu'
import DataTable from 'primevue/datatable'
// import DataView from 'primevue/dataview'
// import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'
import Dialog from 'primevue/dialog'
import Divider from 'primevue/divider'
import Dropdown from 'primevue/dropdown'
// import Fieldset from 'primevue/fieldset'
// import FileUpload from 'primevue/fileupload'
// import FullCalendar from 'primevue/fullcalendar'
import InlineMessage from 'primevue/inlinemessage'
// import Inplace from 'primevue/inplace'
// import InputMask from 'primevue/inputmask'
import InputNumber from 'primevue/inputnumber'
import InputSwitch from 'primevue/inputswitch'
import InputText from 'primevue/inputtext'
// import Knob from 'primevue/knob'
// import Galleria from 'primevue/galleria'
// import Listbox from 'primevue/listbox'
// import MegaMenu from 'primevue/megamenu'
import Menu from 'primevue/menu'
// import Menubar from 'primevue/menubar'
import Message from 'primevue/message'
import MultiSelect from 'primevue/multiselect'
// import OrderList from 'primevue/orderlist'
// import OrganizationChart from 'primevue/organizationchart'
import OverlayPanel from 'primevue/overlaypanel'
// import Paginator from 'primevue/paginator'
import Panel from 'primevue/panel'
// import PanelMenu from 'primevue/panelmenu'
import Password from 'primevue/password'
// import PickList from 'primevue/picklist'
import ProgressBar from 'primevue/progressbar'
// import Rating from 'primevue/rating'
import RadioButton from 'primevue/radiobutton'
import Ripple from 'primevue/ripple'
import SelectButton from 'primevue/selectbutton'
// import ScrollPanel from 'primevue/scrollpanel'
// import ScrollTop from 'primevue/scrolltop'
import Slider from 'primevue/slider'
// import Sidebar from 'primevue/sidebar'
import Skeleton from 'primevue/skeleton'
// import SplitButton from 'primevue/splitbutton'
// import Splitter from 'primevue/splitter'
// import SplitterPanel from 'primevue/splitterpanel'
// import Steps from 'primevue/steps'
// import TabMenu from 'primevue/tabmenu'
import Tag from 'primevue/tag'
// import TieredMenu from 'primevue/tieredmenu'
import Textarea from 'primevue/textarea'
// import Timeline from 'primevue/timeline'
// import Toast from 'primevue/toast'
import ToastWrapper from './components/ui/ToastWrapper'
import ToastService from 'primevue/toastservice'
import Toolbar from 'primevue/toolbar'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Tooltip from 'primevue/tooltip'
// import ToggleButton from 'primevue/togglebutton'
// import Tree from 'primevue/tree'
// import TreeTable from 'primevue/treetable'
// import TriStateCheckbox from 'primevue/tristatecheckbox'

// import CodeHighlight from './AppCodeHighlight'

// import 'primevue/resources/primevue.min.css'
// import 'primeflex/primeflex.css'
// import 'primeicons/primeicons.css'
// import 'prismjs/themes/prism-coy.css'

import AuthService from './services/AuthService'
import AppGuest from './app/AppGuest'
import BoardModal from './components/modal/BoardModal'

// XAIRO Widgets
import WidgetModal from './components/modal/WidgetModal'

import WidgetsSkeleton from './components/skeleton/WidgetsSkeleton'
import ApiKeySkeleton from './components/skeleton/ApiKeySkeleton'

const authService = new AuthService()

authService.setAuthUser().then(() => {
    app.config.globalProperties.$echo = echoInit()
})

router.beforeEach(function (to, from, next) {
    window.scrollTo(0, 0)
    next()
})

const app = createApp({
    computed: {
        ViewComponent () {
            return this.$route.meta.layout ? this.$route.meta.layout : AppGuest
        }
    },
    render () {
        return h(this.ViewComponent)
    }
})
moment.locale('ru')

app.config.globalProperties.$moment = moment
app.config.globalProperties.$appState = reactive({ inputStyle: 'outlined' })
app.config.devtools = true

app.use(VueGridLayout)
app.use(store)
app.use(PrimeVue, {
    ripple: true,
    locale: localeTemplate
})
app.use(ConfirmationService)
app.use(ToastService)
app.use(router)
app.directive('tooltip', Tooltip)
app.directive('ripple', Ripple)
// app.directive('code', CodeHighlight)
app.directive('badge', BadgeDirective)

// app.component('Accordion', Accordion)
// app.component('AccordionTab', AccordionTab)
// app.component('AutoComplete', AutoComplete)
app.component('Avatar', Avatar)
// app.component('AvatarGroup', AvatarGroup)
app.component('Badge', Badge)
// app.component('Breadcrumb', Breadcrumb)
app.component('BlockUI', BlockUI)
app.component('Button', Button)
app.component('Calendar', Calendar)
app.component('Card', Card)
// app.component('Carousel', Carousel)
app.component('Chart', Chart)
app.component('Checkbox', Checkbox)
app.component('Chip', Chip)
app.component('Chips', Chips)
app.component('ColorPicker', ColorPicker)
app.component('Column', Column)
// app.component('ConfirmDialog', ConfirmDialog)
app.component('ConfirmPopup', ConfirmPopup)
// app.component('ContextMenu', ContextMenu)
app.component('DataTable', DataTable)
// app.component('DataView', DataView)
// app.component('DataViewLayoutOptions', DataViewLayoutOptions)
app.component('Dialog', Dialog)
app.component('Divider', Divider)
app.component('Dropdown', Dropdown)
// app.component('Fieldset', Fieldset)
// app.component('FileUpload', FileUpload)
// app.component('FullCalendar', FullCalendar)
app.component('InlineMessage', InlineMessage)
// app.component('Inplace', Inplace)
// app.component('InputMask', InputMask)
app.component('InputNumber', InputNumber)
app.component('InputSwitch', InputSwitch)
app.component('InputText', InputText)
// app.component('Galleria', Galleria)
// app.component('Knob', Knob)
// app.component('Listbox', Listbox)
// app.component('MegaMenu', MegaMenu)
app.component('Menu', Menu)
// app.component('Menubar', Menubar)
app.component('Message', Message)
app.component('MultiSelect', MultiSelect)
// app.component('OrderList', OrderList)
// app.component('OrganizationChart', OrganizationChart)
app.component('OverlayPanel', OverlayPanel)
// app.component('Paginator', Paginator)
app.component('Panel', Panel)
// app.component('PanelMenu', PanelMenu)
app.component('Password', Password)
// app.component('PickList', PickList)
app.component('ProgressBar', ProgressBar)
app.component('RadioButton', RadioButton)
// app.component('Rating', Rating)
app.component('SelectButton', SelectButton)
// app.component('ScrollPanel', ScrollPanel)
// app.component('ScrollTop', ScrollTop)
app.component('Slider', Slider)
// app.component('Sidebar', Sidebar)
app.component('Skeleton', Skeleton)
// app.component('SplitButton', SplitButton)
// app.component('Splitter', Splitter)
// app.component('SplitterPanel', SplitterPanel)
// app.component('Steps', Steps)
// app.component('TabMenu', TabMenu)
app.component('TabView', TabView)
app.component('TabPanel', TabPanel)
app.component('Tag', Tag)
app.component('Textarea', Textarea)
// app.component('TieredMenu', TieredMenu)
// app.component('Timeline', Timeline)
// app.component('Toast', Toast)
app.component('ToastWrapper', ToastWrapper)
app.component('Toolbar', Toolbar)
// app.component('ToggleButton', ToggleButton)
// app.component('Tree', Tree)
// app.component('TreeTable', TreeTable)
// app.component('TriStateCheckbox', TriStateCheckbox)
app.component('BoardModal', BoardModal)
app.component('WidgetModal', WidgetModal)

// XAIRO Widgets
const files = require.context('./components/widgets', true, /.vue$/i)
files.keys().map(function (key) {
    app.component(key.split('/').pop().split('.')[0], files(key).default)
})

app.component('WidgetsSkeleton', WidgetsSkeleton)
app.component('ApiKeySkeleton', ApiKeySkeleton)

router.isReady().then(() => {
    app.mount('#app')
})

// console.log('%cX%cX%cXAIRO %cCurrent version 0.1.7', 'display:inline-block;max-width:20px;height:56px;overflow:hidden;font-weight: bold; font-size:56px;color: rgb(255,0,0);', 'display:inline-block;margin-left:-12px;font-weight:bold; font-size:56px;color: rgb(0,0,0);max-width:40px', 'display:inline-block;margin-top:14px;font-weight: bold; font-size:30px;color: rgb(0,0,0); text-shadow: 1px 1px 0 rgb(150,0,0), 2px 2px 0 rgb(255,0,0)', 'display:block;font-weight: bold; font-size: 10px;color: #000')
if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
    console.log('%cXairoTrade', 'line-height:100px;font-size:50px;color:#FFFFFF;text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(255,255,255,.1), 0 0 5px rgba(255,255,255,.1), 0 1px 3px rgba(255,255,255,.3), 0 3px 5px rgba(255,255,255,.2), 0 5px 10px rgba(255,255,255,.25), 0 10px 10px rgba(255,255,255,.2), 0 20px 20px rgba(255,255,255,.15);')
    console.log('%cCurrent version 0.1.7 🚀', 'font-weight: bold; font-size: 10px;color: #fff')
} else {
    console.log('%cXairoTrade', 'line-height:100px;font-size:50px;color: #131313;letter-spacing: .15em; text-shadow: 1px -1px 0 #767676, -1px 2px 1px #737272, -2px 4px 1px #767474, -3px 6px 1px #787777, -4px 8px 1px #7b7a7a, -5px 10px 1px #7f7d7d, -6px 12px 1px #828181, -7px 14px 1px #868585, -8px 16px 1px #8b8a89, -9px 18px 1px #8f8e8d, -10px 20px 1px #949392, -11px 22px 1px #999897, -12px 24px 1px #9e9c9c, -13px 26px 1px #a3a1a1, -14px 28px 1px #a8a6a6, -15px 30px 1px #adabab, -16px 32px 1px #b2b1b0, -17px 34px 1px #b7b6b5, -18px 36px 1px #bcbbba, -19px 38px 1px #c1bfbf, -20px 40px 1px #c6c4c4, -21px 42px 1px #cbc9c8, -22px 44px 1px #cfcdcd, -23px 46px 1px #d4d2d1, -24px 48px 1px #d8d6d5, -25px 50px 1px #dbdad9, -26px 52px 1px #dfdddc, -27px 54px 1px #e2e0df, -28px 56px 1px #e4e3e2;')
    console.log('%cCurrent version 0.1.7 🚀', 'font-weight: bold; font-size: 10px;color: #000')
}

import vuexStore from '../../core/vuexStore'

export const { state, getters, mutations, actions, namespaced } = vuexStore({
    routes: {
        getFuturesTrades: {
            isLoading: true,
            isUpdateWithReplace: true,
            isSaveLastRequest: true,
            endpoint: '/user/orders/futures/{orderId}/trades'
        },
        getMarginTrades: {
            isLoading: true,
            isUpdateWithReplace: true,
            isSaveLastRequest: true,
            endpoint: '/user/orders/margin/{orderId}/trades'
        },
        getSpotTrades: {
            isLoading: true,
            isUpdateWithReplace: true,
            isSaveLastRequest: true,
            endpoint: '/user/orders/spot/{orderId}/trades'
        }
    },
    getters: {
        lastOrderId: state => state.getFuturesTradesLastRequest ? state.getFuturesTradesLastRequest._params.orderId : null
    }
})

<template>
    <Card class="graph-widget-layout" :style="preview && 'width:100%;height:300px'">
        <template #title>
            <div class="flex">
                <h5 class="text-center mr-auto mb-0 overflow-hidden white-space-nowrap text-overflow-ellipsis"
                    v-tooltip.bottom="widget.title">{{ widget.title }}</h5>
            </div>
        </template>
        <template #content>
            <component
                :demo="demo"
                :preview="preview"
                :key="widget.id"
                :is="`${widget.widget_action.class_name}Action${widget.widget_type.class_name}WidgetLayout`"
                :widget="widget"/>
        </template>
    </Card>
</template>

<script>
export default {
    name: 'GraphWidgetLayout',
    props: {
        demo: {
            type: Boolean,
            default: false
        },
        preview: {
            type: Boolean,
            default: false
        },
        widget: {
            required: true
        }
    }
}
</script>

<style lang="scss">
.graph-widget-layout {
    width: 100%;

    .p-card-body {
        height: 100%;

        .p-card-content {
            height: 100%;
            width: 100%;
            display:flex;
            align-items: center;
            justify-content: center;

            .p-chart {
                height: 100%;
                width:100%;
                max-height: 100%;
                padding: 0 0 1rem 0;
            }
        }
    }
}
</style>

import axios from 'axios'
import AuthService from '../services/AuthService'

const api = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: true,
    headers: {
        Accept: 'application/json',
        'Control-type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
    }
})

api.interceptors.response.use(
    response => response,
    async (error) => {
        if (error.response && error.response.status === 401) {
            const authService = new AuthService()
            await authService.clearAuthData()
            location.reload()
        } else {
            return Promise.reject(error)
        }
    }
)

export default api

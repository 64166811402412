<template>
    <div class="grid">
        <div class="col-6">
            <div class="field">
                <label for="widgetFontSizeSettings">Размер шрифта</label>
                <InputNumber
                    id="widgetFontSizeSettings"
                    :modelValue="fontSize"
                    @update:modelValue="$emit('update-params', {font_size: $event})"
                    mode="decimal"
                    showButtons
                    :min="1"
                    :max="13"
                    placeholder="Выбрать размер шрифта"
                    :area-invalid="fontSizeError ? true : null"
                    :aria-describedby="fontSizeError ? fontSizeError : null"
                    :class="{ 'p-invalid' : fontSizeError}"/>
                <div v-if="fontSizeError" class="p-error text-left">
                    <small>{{ fontSizeError }}</small>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="field">
                <div class="label mb-2">Показать валюту</div>
                <div class="flex align-items-center py-1">
                    <InputSwitch
                        id="widgetShowSymbolSettings"
                        :modelValue="showSymbol"
                        @update:modelValue="$emit('update-params', {show_symbol: $event})"
                        :area-invalid="showSymbolError ? true : null"
                        :aria-describedby="showSymbolError ? showSymbolError : null"
                        :class="[{'p-invalid' : showSymbolError}, 'mt-auto']"/>
                </div>
                <div v-if="showSymbolError" class="p-error text-left">
                    <small>{{ showSymbolError }}</small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, getCurrentInstance } from 'vue'

export default {
    name: 'SpotBalanceActionNumberWidgetParams',
    emits: ['update-params'],
    props: {
        demo: {
            type: Boolean,
            default: false
        },
        params: {
            required: true
        },
        errors: {
            required: true
        }
    },
    setup (props) {
        const app = getCurrentInstance()

        if (props.demo) {
            app.emit('update-params', { font_size: 10, show_symbol: true })
        }
        const fontSize = computed(() => props.params.font_size ? props.params.font_size : 10)
        const showSymbol = computed(() => props.params.show_symbol)

        const fontSizeError = computed(() => props.errors['params.font_size'] ? props.errors['params.font_size'] : null)
        const showSymbolError = computed(() => props.errors['params.show_symbol'] ? props.errors['params.show_symbol'] : null)

        return {
            fontSize,
            fontSizeError,
            showSymbol,
            showSymbolError
        }
    }
}
</script>

const localeTemplate = {
    startsWith: 'Начинается с',
    contains: 'Содержит',
    notContains: 'Не сожержит',
    endsWith: 'Оканчивается на',
    equals: 'Равно',
    notEquals: 'Не равно',
    noFilter: 'Не фильтровать',
    lt: 'Меньше чем',
    lte: 'Меньше чем или равно',
    gt: 'Больше чем',
    gte: 'Больше чем или равно',
    dateIs: 'Дата равна',
    dateIsNot: 'Дата не равна',
    dateBefore: 'Дата до',
    dateAfter: 'Дата после',
    clear: 'Очистить',
    apply: 'Применить',
    matchAll: 'Соответствует всем',
    matchAny: 'Соответствует любому',
    addRule: 'Добавить правило',
    removeRule: 'Удалить правило',
    accept: 'Да',
    reject: 'Нет',
    choose: 'Выбрать',
    upload: 'Загрузить',
    cancel: 'Отмена',
    dayNames: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
    dayNamesShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    monthNamesShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сен', 'Окт', 'Нояб', 'Дек'],
    today: 'Сегодня',
    weekHeader: 'Неделя',
    firstDayOfWeek: 0,
    dateFormat: 'dd/mm/yy',
    weak: 'Слабый',
    medium: 'Средний',
    strong: 'Сложный',
    passwordPrompt: 'Введите пароль',
    emptyFilterMessage: 'Ничего не найдено',
    emptyMessage: 'Нет доступных вариантов'
}

export default localeTemplate

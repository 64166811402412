<template>
    <div>
        <ToastWrapper/>
            <router-view/>
    </div>
</template>

<script>

export default {}
</script>

<style lang="scss">
</style>

export const state = () => ({
    toast: null
})

export const mutations = {
    SET_TOAST: (state, payload) => {
        state.toast = payload
    }
}

export const actions = {
    setToast: async (context, payload) => {
        context.commit('SET_TOAST', payload)
    }
}

export const namespaced = true

<template>
    <Card class="number-widget-layout" :style="preview && 'width:350px;height:200px'">
        <template #title>
            <div class="flex">
                <h5 class="text-center mr-auto mb-0 overflow-hidden white-space-nowrap text-overflow-ellipsis" v-tooltip.bottom="widget.title">{{ widget.title }}</h5>
            </div>
        </template>
        <template #content class="test">
            <component
                :demo="demo"
                :key="widget.id"
                :is="`${widget.widget_action.class_name}Action${widget.widget_type.class_name}WidgetLayout`"
                :widget="widget"/>
        </template>
    </Card>
</template>

<script>
export default {
    name: 'NumberWidgetLayout',
    props: {
        demo: {
            type: Boolean,
            default: false
        },
        preview: {
            type: Boolean,
            default: false
        },
        widget: {
            required: true
        }
    }
}
</script>

<style lang="scss">
.number-widget-layout {
    width:100%;

    .p-card-body {
        height: 100%;

        .p-card-content {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
    }
}
</style>

import { ref } from 'vue'

export default class FormService {
    fillErrors (exception, form) {
        if (exception.response && exception.response.status === 422) {
            form.errors = ref({})
            Object.entries(exception.response.data.errors).forEach(item => {
                Object.assign(form.errors, {
                    [item[0]]: item[1].length ? item[1][0] : item[1]
                })
            })
        }
    }
}

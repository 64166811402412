<template>
    <Skeleton
        width="10%"
        height="2rem"
        class="mb-2 ml-auto"
        border-radius="4px"/>
    <Skeleton
        width="100%"
        height="4rem"
        class="mb-2"
        border-radius="4px"/>
    <Skeleton
        width="100%"
        height="12rem"
        class="mb-4"
        border-radius="4px"/>
    <Skeleton
        width="100%"
        height="4rem"
        class="mb-2"
        border-radius="4px"/>
    <Skeleton
        width="100%"
        height="8rem"
        class="mb-4"
        border-radius="4px"/>
</template>

<script>
export default {
    name: 'ApiKeySkeleton'
}
</script>

<style scoped>

</style>

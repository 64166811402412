import vuexStore from '../../core/vuexStore'

export const { state, getters, mutations, actions, namespaced } = vuexStore({
    routes: {
        getAccounts: {
            isLoading: true,
            isUpdateWithReplace: true,
            endpoint: '/user/accounts'
        },
        getAccountsWithApiKeys: {
            isLoading: true,
            isUpdateWithReplace: true,
            endpoint: '/user/accounts?with=apiKeys'
        },
        showAccount: {
            isLoading: true,
            endpoint: '/user/accounts/{account}'
        },
        updateAccount: {
            isLoading: true,
            method: 'put',
            endpoint: '/user/accounts/{account}'
        },
        storeAccount: {
            isLoading: true,
            method: 'post',
            endpoint: '/user/accounts'
        },
        deleteAccount: {
            isLoading: true,
            method: 'delete',
            endpoint: '/user/accounts/{account}'
        }
    }
})

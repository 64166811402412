import { store } from '../store'

export default class ApiKeyService {
    async getApiKeys (params) {
        return await store.dispatch('apiKey/getApiKeys', {
            _data: params
        })
    }

    async getApiKeysWithFuturesBalance (params) {
        return await store.dispatch('apiKey/getApiKeysWithFuturesBalance', {
            _data: params
        })
    }

    async getApiKeysWithSpotBalance (params) {
        return await store.dispatch('apiKey/getApiKeysWithSpotBalance', {
            _data: params
        })
    }

    async showApiKey (id) {
        return await store.dispatch('apiKey/showApiKey', {
            _params: {
                apiKey: id
            }
        })
    }

    async updateApiKey (id, data) {
        data.color = data.color.charAt(0) === '#' ? data.color : '#' + data.color
        return await store.dispatch('apiKey/updateApiKey', {
            _params: {
                apiKey: id
            },
            _data: data
        })
    }

    async storeApiKey (data) {
        data.color = data.color.charAt(0) === '#' ? data.color : '#' + data.color
        return await store.dispatch('apiKey/storeApiKey', {
            _data: data
        })
    }

    async deleteApiKey (id) {
        return await store.dispatch('apiKey/deleteApiKey', {
            _params: {
                apiKey: id
            }
        })
    }

    activeOptions () {
        return [
            { label: 'Активный', value: true },
            { label: 'Не активный', value: false }
        ]
    }

    defaultColor () {
        return '#FFCC80'
    }
}

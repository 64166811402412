import { createStore } from 'vuex'
import * as auth from './modules/auth'
import * as app from './modules/app'
import * as account from './modules/account'
import * as apiKey from './modules/apiKey'
import * as order from './modules/order'
import * as symbol from './modules/symbol'
import * as user from './modules/user'
import * as trade from './modules/trade'
import * as board from './modules/board'
import * as widget from './modules/widget'
import * as widgetType from './modules/widgetType'
import * as widgetAction from './modules/widgetAction'

export const store = createStore({
    strict: true,
    modules: {
        app,
        auth,
        account,
        apiKey,
        order,
        symbol,
        user,
        trade,
        board,
        widget,
        widgetType,
        widgetAction
    }
})

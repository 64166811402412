import vuexStore from '../../core/vuexStore'

export const { state, getters, mutations, actions, namespaced } = vuexStore({
    routes: {
        getWidgetTypes: {
            isLoading: true,
            isUpdateWithReplace: true,
            endpoint: '/widget-types'
        }
    }
})

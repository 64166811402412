import vuexStore from '../../core/vuexStore'

export const { state, getters, mutations, actions, namespaced } = vuexStore({
    routes: {
        getBoards: {
            isLoading: true,
            isUpdateWithReplace: true,
            endpoint: '/user/boards'
        },
        showBoard: {
            isLoading: true,
            endpoint: '/user/boards/{boardId}'
        },
        editBoard: {
            isLoading: true,
            endpoint: '/user/boards/{boardId}'
        },
        updateBoard: {
            isLoading: true,
            method: 'put',
            endpoint: '/user/boards/{boardId}'
        },
        storeBoard: {
            isLoading: true,
            method: 'post',
            endpoint: '/user/boards'
        },
        deleteBoard: {
            isLoading: true,
            method: 'delete',
            endpoint: '/user/boards/{boardId}'
        }
    },
    states: {
        modalState: false
    },
    getters: {
        isModalVisible: state => !!state.modalState,
        isModalStateEdit: state => state.modalState === 'edit',
        isModalStateCreate: state => state.modalState === 'create'
    },
    mutations: {
        SET_MODAL_STATE: (state, payload) => {
            state.modalState = payload
        }
    },
    actions: {
        setModalState: async (context, payload) => {
            context.commit('SET_MODAL_STATE', payload)
        }
    }
})

import vuexStore from '../../core/vuexStore'

export const { state, getters, mutations, actions, namespaced } = vuexStore({
    routes: {
        getProfile: {
            method: 'get',
            isLoading: true,
            isUpdateWithReplace: true,
            endpoint: '/user/profile'
        },
        updateProfile: {
            method: 'put',
            isLoading: true,
            endpoint: '/user/profile'
        },
        changePassword: {
            method: 'put',
            isLoading: true,
            endpoint: '/user/password-change'
        },
        updateSettings: {
            method: 'put',
            isLoading: true,
            endpoint: '/user/settings'
        }
    },
    mutations: {
        UPDATE_USER_FUTURES_TOTAL_BALANCE: (state, payload) => {
            if (state.getProfile) {
                state.getProfile.futuresTotalBalance = payload
            }
        },
        UPDATE_USER_SPOT_TOTAL_BALANCE: (state, payload) => {
            if (state.getProfile) {
                state.getProfile.spotTotalBalance = payload
            }
        },
        UPDATE_GET_PROFILE_SETTINGS: (state, payload) => {
            if (state.getProfile) {
                Object.assign(state.getProfile.settings, payload)
            }
        }
    },
    actions: {
        updateUserFuturesTotalBalance: async (context, payload) => {
            context.commit('UPDATE_USER_FUTURES_TOTAL_BALANCE', payload)
        },

        updateUserSpotTotalBalance: async (context, payload) => {
            context.commit('UPDATE_USER_SPOT_TOTAL_BALANCE', payload)
        },

        updateGetProfileSettings: async (context, payload) => {
            context.commit('UPDATE_GET_PROFILE_SETTINGS', payload)
        }
    }
})

import { store } from '../store'

export default class ToastMessageService {
    errorOptions = {
        severity: 'error',
        summary: 'Ошибка',
        life: 3000
    }

    successOptions = {
        severity: 'success',
        summary: 'Успешно',
        life: 3000
    }

    warningOptions = {
        severity: 'warn',
        summary: 'Внимание',
        life: 3000
    }

    HTTP_500 = {
        ...this.errorOptions,
        ...{
            detail: 'Сервер не отвечает'
        }
    }

    HTTP_422 = {
        ...this.warningOptions,
        ...{
            detail: 'Данные зполнены не корректно'
        }
    }

    HTTP_404 = {
        ...this.warningOptions,
        ...{
            detail: 'Ресурс не найден'
        }
    }

    async add (params) {
        await store.dispatch('app/setToast', params)
    }
}

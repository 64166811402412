import vuexStore from '../../core/vuexStore'

export const { state, getters, mutations, actions, namespaced } = vuexStore({
    routes: {
        getSymbols: {
            isLoading: true,
            isUpdateWithReplace: true,
            endpoint: '/symbols'
        }
    }
})

<template>
    <div
        v-for="row in skeleton"
        :key="row"
        class="flex">
        <Skeleton
            v-for="col in row.cols"
            :width="`${100/row.cols}%`"
            height="12rem"
            class="mr-3 mb-3"
            :key="col"/>
    </div>
</template>

<script>
export default {
    name: 'WidgetsSkeleton',
    props: {
        skeleton: {
            default:
                [
                    {
                        cols: 5
                    },
                    {
                        cols: 4
                    }
                ]
        }
    }
}
</script>

<style scoped>

</style>

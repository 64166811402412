<template>
    <Dialog
        :visible="visible"
        @update:visible="closeModal"
        :modal="true"
        :style="{width: '65vw'}"
        :breakpoints="{'960px': '75vw', '640px': '100vw'}"
        :maximizable="true"
        :maximized="true"
        :header="isModalStateCreate ? 'Новый виджет' : 'Редактировать виджет'"
        class="p-fluid">
        <div class="grid">
            <div class="col-12 md:col-8">
                <div class="field">
                    <label for="widgetTitle">Заголовок для виджета</label>
                    <InputText v-model="widgetForm.fields.title" id="widgetTitle"
                               :area-invalid="widgetForm.errors.title ? true : null"
                               :aria-describedby="widgetForm.errors.title ? widgetForm.errors.title : null"
                               :class="{ 'p-invalid' : widgetForm.errors.title }"/>
                    <div v-if="widgetForm.errors.title" class="p-error text-left">
                        <small>{{ widgetForm.errors.title }}</small></div>
                </div>
                <div class="field">
                    <label for="widgetApiKeys">Апи ключи</label>
                    <MultiSelect
                        id="widgetApiKeys"
                        :modelValue="widgetForm.fields.api_keys"
                        @update:modelValue="updateWidgetApiKeys"
                        :options="apiKeysOptions ? apiKeysOptions : []"
                        :loading="apiKeysOptionsLoading"
                        :disabled="apiKeysOptionsLoading"
                        dataKey="id"
                        optionValue="id"
                        optionLabel="note"
                        show-clear
                        display="chip"
                        :area-invalid="widgetForm.errors.api_keys ? true : null"
                        :aria-describedby="widgetForm.errors.api_keys ? widgetForm.errors.api_keys : null"
                        :placeholder="apiKeysOptionsLoading ? 'Загрузка ключей...' : 'Выбрать ключ'">
                        <template #option="slotProps">
                            <div class="p-multiselect-car-option">
                                <span> <i class="pi pi-star"
                                          :style="`color:${slotProps.option.color ? slotProps.option.color : defaultColor}`"/>
                                    {{ slotProps.option.note }}</span>
                            </div>
                        </template>
                    </MultiSelect>
                    <div v-if="widgetForm.errors.api_keys" class="p-error text-left">
                        <small>{{ widgetForm.errors.api_keys }}</small></div>
                </div>
                <div
                    v-if="widgetForm.fields.widget_type && widgetForm.fields.widget_action"
                    class="flex align-items-center justify-content-center p-4 widget-preview-wrapper">
                    <WidgetLayout
                        :demo="!widgetForm.fields.id"
                        :preview="true"
                        :showControls="false"
                        :widget="widgetForm.fields"/>
                </div>
            </div>
            <div class="col-12 md:col-4">
                <div class="field">
                    <label for="widgetTypeId">Тип виджета</label>
                    <Dropdown
                        :modelValue="widgetForm.fields.widget_type_id"
                        @update:modelValue="getWidgetActionByWidgetTypeId"
                        :options="widgetTypesOptions ? widgetTypesOptions : []"
                        optionValue="id"
                        optionLabel="name"
                        :area-invalid="widgetForm.errors.widget_type_id ? true : null"
                        :aria-describedby="widgetForm.errors.widget_type_id ? widgetForm.errors.widget_type_id : null"
                        :placeholder="widgetTypesOptionsLoading ? 'Загрузка ...' : 'Выбрать тип виджета'"/>
                    <div v-if="widgetForm.errors.widget_type_id" class="p-error text-left">
                        <small>{{ widgetForm.errors.widget_type_id }}</small></div>

                </div>
                <div v-if="widgetForm.fields.widget_type_id" class="field">
                    <label for="widgetActionId">Источник</label>
                    <Dropdown
                        :modelValue="widgetForm.fields.widget_action_id"
                        @update:modelValue="getWidgetActionName"
                        id="widgetActionId"
                        :options="widgetActionsOptions ? widgetActionsOptions : []"
                        optionValue="id"
                        optionLabel="name"
                        :area-invalid="widgetForm.errors.widget_action_id ? true : null"
                        :aria-describedby="widgetForm.errors.widget_action_id ? widgetForm.errors.widget_action_id : null"
                        :placeholder="widgetActionsOptionsLoading ? 'Загрузка ...' : 'Выбрать источник виджета'"/>
                    <div v-if="widgetForm.errors.widget_action_id" class="p-error text-left">
                        <small>{{ widgetForm.errors.widget_action_id }}</small></div>

                </div>
                <component
                    v-if="widgetForm.fields.widget_action_id"
                    :demo="!widgetForm.fields.id"
                    :is="widgetTypeComponentName"
                    :params="widgetForm.fields.params"
                    :widgetActionComponentName="widgetActionComponentName"
                    :errors="widgetForm.errors"
                    @UpdateParams="updateParams"
                    @UpdateParamsWithResult="updateParamsWithResult"
                />
            </div>
        </div>
        <InlineMessage
            v-if="widgetForm.errors.widget"
            severity="warn">
            {{ widgetForm.errors.widget }}
        </InlineMessage>
        <template #footer>
            <Button
                label="Отмена"
                icon="pi pi-times"
                class="p-button-text"
                @click="closeModal"
            />
            <Button
                v-if="isModalStateEdit"
                label="Сохранить"
                icon="pi pi-check"
                class="p-button-text"
                :loading="loading"
                @click="updateWidget"
            />
            <Button
                v-else-if="isModalStateCreate"
                label="Добавить"
                icon="pi pi-plus"
                class="p-button-text"
                :loading="loading"
                @click="storeWidget"
            />
        </template>
    </Dialog>
</template>

<script>
import { reactive, computed, watch } from 'vue'
import { useStore } from 'vuex'
import ToastMessageService from '../../services/ToastMessageService'
import FormService from '../../services/FormService'
import WidgetService from '../../services/WidgetService'
import ApiKeyService from '../../services/ApiKeyService'
import WidgetTypeService from '../../services/WidgetTypeService'
import WidgetActionService from '../../services/WidgetActionService'

const merge = require('deepmerge')
export default {
    name: 'WidgetModal',
    setup () {
        const store = useStore()
        const widgetService = new WidgetService()
        const formService = new FormService()
        const toastMessageService = new ToastMessageService()
        const apiKeyService = new ApiKeyService()
        const widgetTypeService = new WidgetTypeService()
        const widgetActionService = new WidgetActionService()

        const defaultColor = apiKeyService.defaultColor()
        const loading = computed(() => store.state.widget.storeWidgetLoading || store.state.widget.updateWidgetLoading)
        const board = computed(() => store.state.board.showBoard)
        const visible = computed(() => store.getters['widget/isModalVisible'])
        const showWidget = computed(() => store.state.widget.showWidget)
        const isModalStateEdit = computed(() => store.getters['widget/isModalStateEdit'])
        const isModalStateCreate = computed(() => store.getters['widget/isModalStateCreate'])

        const apiKeysOptions = computed(() => store.state.apiKey.getApiKeys)
        const widgetTypesOptions = computed(() => store.state.widgetType.getWidgetTypes)
        const widgetActionsOptions = computed(() => store.state.widgetAction.getWidgetActions)
        const apiKeysOptionsLoading = computed(() => store.state.apiKey.getApiKeysLoading)
        const widgetTypesOptionsLoading = computed(() => store.state.widgetType.getWidgetTypesLoading)
        const widgetActionsOptionsLoading = computed(() => store.state.widgetAction.getWidgetActionsLoading)

        widgetTypeService.getWidgetTypes()
        apiKeyService.getApiKeys()

        const defaultWidgetForm = () => {
            return {
                fields: {
                    title: 'Новый виджет',
                    board_id: board.value.id,
                    api_keys: [],
                    api_keys_models: [],
                    widget_type_id: null,
                    widget_type: null,
                    widget_action_id: null,
                    widget_action: null,
                    params: {},
                    id: null
                },
                errors: {
                    title: null,
                    widget: null,
                    params: null
                }
            }
        }
        const widgetForm = reactive(defaultWidgetForm())
        const widgetTypeComponentName = computed(() => {
            const type = widgetTypesOptions.value ? widgetTypesOptions.value.find(w => w.id === widgetForm.fields.widget_type_id) : null
            return type ? `${type.class_name}WidgetParams` : null
        })
        const widgetActionComponentName = computed(() => {
            const action = widgetActionsOptions.value ? widgetActionsOptions.value.find(w => w.id === widgetForm.fields.widget_action_id) : null
            return action && widgetTypeComponentName.value ? `${action.class_name}Action${widgetTypeComponentName.value}` : null
        })

        const updateParams = ($event) => {
            widgetForm.fields.params = merge(widgetForm.fields.params, $event)
        }
        const updateParamsWithResult = ($event) => {
            updateParams($event)
            widgetForm.fields.result = null
        }

        watch(showWidget, (state, prevState) => {
            if (state) {
                const parsedWidget = JSON.parse(JSON.stringify(state))
                if (parsedWidget.api_keys) {
                    parsedWidget.api_keys_models = parsedWidget.api_keys
                    parsedWidget.api_keys = parsedWidget.api_keys.map(widget => widget.id)
                }
                widgetActionService.getWidgetActions(parsedWidget.widget_type_id)
                Object.assign(widgetForm.fields, parsedWidget)
            }
        })

        const getWidgetActionName = ($event) => {
            widgetForm.fields.widget_action_id = $event
            widgetForm.fields.widget_action = { class_name: widgetActionsOptions.value.find(item => item.id === $event).class_name }
        }

        const getWidgetActionByWidgetTypeId = ($event) => {
            widgetActionService.getWidgetActions($event)
            widgetForm.fields.widget_action_id = null
            widgetForm.fields.widget_action = null
            widgetForm.fields.widget_type_id = $event
            widgetForm.fields.widget_type = { class_name: widgetTypesOptions.value.find(item => item.id === $event).class_name }
        }

        const updateWidget = async () => {
            try {
                await widgetService.updateWidget(board.value.id, widgetForm.fields.id, widgetForm.fields)
                await toastMessageService.add({
                    detail: 'Виджет сохранен'
                })
                closeModal()
                await widgetService.resetWidgets()
                await widgetService.getWidgets(board.value.id)
            } catch (exception) {
                formService.fillErrors(exception, widgetForm)
            }
        }

        const storeWidget = async () => {
            try {
                await widgetService.storeWidget(board.value.id, widgetForm.fields)
                await toastMessageService.add({
                    detail: 'Виджет добавлен'
                })
                closeModal()
                await widgetService.resetWidgets()
                await widgetService.getWidgets(board.value.id)
            } catch (exception) {
                formService.fillErrors(exception, widgetForm)
            }
        }

        const updateWidgetApiKeys = ($event) => {
            widgetForm.fields.api_keys = $event
            widgetForm.fields.api_keys_models = apiKeysOptions.value.filter(apiKey => $event.includes(apiKey.id))
        }

        const closeModal = () => {
            Object.assign(widgetForm, defaultWidgetForm())
            widgetService.modalToggle(false)
        }

        return {
            loading,
            widgetForm,
            storeWidget,
            updateWidget,
            visible,
            isModalStateEdit,
            isModalStateCreate,
            showWidget,
            closeModal,
            apiKeysOptions,
            apiKeysOptionsLoading,
            defaultColor,
            widgetTypesOptions,
            widgetTypesOptionsLoading,
            widgetActionsOptions,
            widgetActionsOptionsLoading,
            getWidgetActionByWidgetTypeId,
            getWidgetActionName,
            widgetTypeComponentName,
            widgetActionComponentName,
            updateParams,
            updateParamsWithResult,
            updateWidgetApiKeys
        }
    }
}
</script>

<style lang="scss">
.widget-preview-wrapper {
    background: #17212f;

    .widget-layout {
        width: 100%
    }
}
</style>

<template>
    <component
        :is="widgetActionComponentName"
        :params="params"
        :demo="demo"
        :errors="errors"
        @UpdateParams="$emit('update-params', $event)"
        @UpdateParamsWithResult="$emit('update-params-with-result', $event)"
    />
</template>

<script>

export default {
    name: 'GraphWidgetParams',
    emits: ['update-params', 'update-params-with-result'],
    props: {
        widgetActionComponentName: {
            type: String
        },
        params: {
            type: Object
        },
        errors: {
            type: Object
        },
        demo: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<template>
    <div class="grid">
        <div class="col-6">
            <div class="field">
                <label for="widgetFontSizeSettings">Размер шрифта</label>
                <InputNumber
                    id="widgetFontSizeSettings"
                    :modelValue="fontSize"
                    @update:modelValue="$emit('update-params', {font_size: $event})"
                    mode="decimal"
                    showButtons
                    :min="1"
                    :max="13"
                    placeholder="Выбрать размер шрифта"
                    :area-invalid="fontSizeError ? true : null"
                    :aria-describedby="fontSizeError ? fontSizeError : null"
                    :class="{ 'p-invalid' : fontSizeError}"/>
                <div v-if="fontSizeError" class="p-error text-left">
                    <small>{{ fontSizeError }}</small>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="field">
                <div class="label mb-2">Показать валюту</div>
                <div class="flex align-items-center py-1">
                    <InputSwitch
                        id="widgetShowSymbolSettings"
                        :modelValue="showSymbol"
                        @update:modelValue="$emit('update-params', {show_symbol: $event})"
                        :area-invalid="showSymbolError ? true : null"
                        :aria-describedby="showSymbolError ? showSymbolError : null"
                        :class="[{'p-invalid' : showSymbolError}, 'mt-auto']"/>
                </div>
                <div v-if="showSymbolError" class="p-error text-left">
                    <small>{{ showSymbolError }}</small>
                </div>
            </div>
        </div>
    </div>
    <div class="field">
        <label for="widgetDatesLastDays">Выбрать период</label>
        <Dropdown
            id="widgetDatesLastDays"
            :disabled="dateFrom || dateTo"
            :modelValue="lastDays"
            @update:modelValue="$emit('update-params', {dates: {last_days:$event}})"
            :options="lastDaysOptions"
            :show-clear="true"
            optionLabel="name"
            optionValue="slug"
            placeholder="Выбрать"
            :area-invalid="lastDaysError ? true : null"
            :aria-describedby="lastDaysError ? lastDaysError : null"
            :class="{ 'p-invalid' : lastDaysError}"/>
        <div v-if="lastDaysError" class="p-error text-left">
            <small>{{ lastDaysError }}</small></div>
    </div>
    <div class="field">
        <label for="widgetDatesFromTo">Или указать дату</label>
        <div class="flex align-items-center">
            <Calendar
                :disabled="lastDays"
                id="widgetDatesFrom"
                :modelValue="dateFrom"
                @update:modelValue="$emit('update-params', {dates: {from:$event, to:dateTo}})"
                :showTime="true"
                hideOnDateTimeSelect
                showButtonBar
                :manualInput="false"
                :area-invalid="dateFromError ? true : null"
                :aria-describedby="dateFromError ? dateFromError : null"
                :class="{ 'p-invalid' : dateFromError}"
            />
            -
            <Calendar
                :disabled="lastDays"
                id="widgetDatesTo"
                :modelValue="dateTo"
                @update:modelValue="$emit('update-params', {dates: {to:$event, from:dateFrom}})"
                :showTime="true"
                hideOnDateTimeSelect
                showButtonBar
                :manualInput="false"
                :area-invalid="dateToError ? true : null"
                :aria-describedby="dateToError ? dateToError : null"
                :class="{ 'p-invalid' : dateToError}"
            />
        </div>
        <div v-if="dateToError" class="p-error text-left">
            <small>{{ dateToError }}</small>
        </div>
        <div v-if="dateFromError" class="p-error text-left">
            <small>{{ dateFromError }}</small>
        </div>
    </div>
</template>

<script>
import { computed, getCurrentInstance } from 'vue'

export default {
    name: 'FuturesBalanceDeltaActionNumberWidgetParams',
    emits: ['update-params'],
    props: {
        demo: {
            type: Boolean,
            default: false
        },
        params: {
            required: true
        },
        errors: {
            required: true
        }
    },
    setup (props) {
        const app = getCurrentInstance()

        if (props.demo) {
            app.emit('update-params', { font_size: 10, show_symbol: true })
        }
        const fontSize = computed(() => props.params.font_size ? props.params.font_size : 10)
        const showSymbol = computed(() => props.params.show_symbol)
        const lastDays = computed(() => props.params.dates ? props.params.dates.last_days : null)
        const dateFrom = computed(() => props.params.dates ? props.params.dates.from : null)
        const dateTo = computed(() => props.params.dates ? props.params.dates.to : null)
        const fontSizeError = computed(() => props.errors['params.font_size'] ? props.errors['params.font_size'] : null)
        const showSymbolError = computed(() => props.errors['params.show_symbol'] ? props.errors['params.show_symbol'] : null)
        const lastDaysError = computed(() => props.errors['params.dates.last_days'] ? props.errors['params.dates.last_days'] : null)
        const dateFromError = computed(() => props.errors['params.dates.from'] ? props.errors['params.dates.from'] : null)
        const dateToError = computed(() => props.errors['params.dates.to'] ? props.errors['params.dates.to'] : null)

        const lastDaysOptions = [
            {
                name: 'За сегодня ',
                slug: 'day'
            },
            {
                name: 'За вчера',
                slug: 'last_day'
            },
            {
                name: 'За неделю',
                slug: 'week'
            },
            {
                name: 'За прошлую неделю',
                slug: 'last_week'
            },
            {
                name: 'За месяц',
                slug: 'month'
            },
            {
                name: 'За прошлый месяц',
                slug: 'last_month'
            },
            {
                name: 'За квартал',
                slug: 'quarter'
            },
            {
                name: 'За прошлый квартал',
                slug: 'last_quarter'
            },
            {
                name: 'За весь год',
                slug: 'year'
            },
            {
                name: 'За прошлый год',
                slug: 'last_year'
            }
        ]

        return {
            lastDays,
            dateFrom,
            dateTo,
            lastDaysOptions,
            lastDaysError,
            dateFromError,
            dateToError,
            fontSize,
            fontSizeError,
            showSymbol,
            showSymbolError
        }
    }
}
</script>

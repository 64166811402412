import vuexStore from '../../core/vuexStore'

export const { state, getters, mutations, actions, namespaced } = vuexStore({
    routes: {
        getApiKeys: {
            isLoading: true,
            isUpdateWithReplace: true,
            endpoint: '/user/api-keys'
        },
        getApiKeysWithFuturesBalance: {
            isLoading: true,
            isUpdateWithReplace: true,
            endpoint: '/user/api-keys?with=futuresBalance'
        },
        getApiKeysWithSpotBalance: {
            isLoading: true,
            isUpdateWithReplace: true,
            endpoint: '/user/api-keys?with=spotBalance'
        },
        showApiKey: {
            isLoading: true,
            endpoint: '/user/api-keys/{apiKey}'
        },
        updateApiKey: {
            isLoading: true,
            method: 'put',
            endpoint: '/user/api-keys/{apiKey}'
        },
        storeApiKey: {
            isLoading: true,
            method: 'post',
            endpoint: '/user/api-keys'
        },
        deleteApiKey: {
            isLoading: true,
            method: 'delete',
            endpoint: '/user/api-keys/{apiKey}'
        }
    }
})

<template>
    <Toast position="bottom-right"/>
</template>

<script>
import Toast from 'primevue/toast'
import { useToast } from 'primevue/usetoast'
import { useStore } from 'vuex'
import { computed, watch } from 'vue'

export default {
    name: 'ToastWrapper',
    components: {
        Toast
    },
    setup () {
        const store = useStore()
        const toast = useToast()
        const toastState = computed(() => store.state.app.toast)
        const defaultOptions = {
            severity: 'success',
            summary: 'Успешно',
            life: 3000
        }
        watch(toastState, (state, prevState) => {
            toast.add({ ...defaultOptions, ...state })
        })

        return {
            toastState
        }
    }
}
</script>

<style scoped>

</style>

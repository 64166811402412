import { store } from '../store'

export default class WidgetActionService {
    async getWidgetActions (widgetType, params) {
        return await store.dispatch('widgetAction/getWidgetActions', {
            _params: {
                widgetTypeId: widgetType
            },
            _data: params
        })
    }
}

<template>
    <component
        :is="widgetActionComponentName"
        :params="params"
        :demo="demo"
        :errors="errors"
        @UpdateParams="$emit('update-params', $event)"
    />
</template>

<script>
import { getCurrentInstance } from 'vue'

export default {
    name: 'NumberWidgetParams',
    emits: ['update-params', 'update-params-with-result'],
    props: {
        widgetActionComponentName: {
            type: String
        },
        params: {
            type: Object
        },
        errors: {
            type: Object
        },
        demo: {
            type: Boolean,
            default: false
        }
    },
    setup (props) {
        const app = getCurrentInstance()
        if (props.demo) {
            app.emit('update-params', {
                position: {
                    x: 0,
                    y: 0,
                    h: 4,
                    w: 4
                }
            })
        }
    }
}
</script>

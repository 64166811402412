<template>
    <div></div>
</template>

<script>

export default {
    name: 'FuturesBalanceActionGraphWidgetParams',
    emits: ['update-params'],
    props: {
        demo: {
            type: Boolean,
            default: false
        },
        params: {
            required: true
        },
        errors: {
            required: true
        }
    },
    setup () {

    }
}
</script>

import { store } from '../store'
import api from '../core/api'

export default class AuthService {
    tokenTitle = 'auth_token'
    userTitle = 'auth_user'

    async login (fields) {
        await store.dispatch('auth/login', {
            _data: fields
        })
        await this.authenticateByToken(store.state.auth.login.auth_token)
    }

    async forgot (fields) {
        await store.dispatch('auth/forgot', {
            _data: fields
        })
    }

    async resetPassword (fields) {
        await store.dispatch('auth/resetPassword', {
            _data: fields
        })
    }

    async register (fields) {
        await store.dispatch('auth/register', {
            _data: fields
        })
        await this.authenticateByToken(store.state.auth.register.auth_token)
    }

    async logout () {
        await store.dispatch('auth/logout')
        await this.clearAuthData()
        location.reload()
    }

    async authenticateByToken (token) {
        try {
            this.setAuthorizationHeader(token)
            this.storeAuthTokenToStorage(token)
            await this.getAuthUser()
            this.storeAuthUserToStorage(store.state.auth.user)
        } catch (exception) {
            await this.clearAuthData()
        }
    }

    setAuthorizationHeader (token = null) {
        api.defaults.headers.common.Authorization = token ? 'Bearer ' + token : ''
    }

    storeAuthTokenToStorage (token) {
        localStorage.setItem(this.tokenTitle, token)
    }

    storeAuthUserToStorage (user) {
        localStorage.setItem(this.userTitle, JSON.stringify(user))
    }

    async enableTwoFactorAuthentication (data) {
        await store.dispatch('auth/enableTwoFactorAuthentication')
    }

    async disableTwoFactorAuthentication (data) {
        await store.dispatch('auth/disableTwoFactorAuthentication')
    }

    async validateTwoFactorAuthentication (code) {
        await store.dispatch('auth/validateTwoFactorAuthentication', {
            _data: code
        })
    }

    async qrCodeTwoFactorAuthentication (data) {
        await store.dispatch('auth/qrCodeTwoFactorAuthentication')
    }

    async getAuthUser () {
        await store.dispatch('auth/user')
    }

    async setAuthUserToStore (user) {
        await store.dispatch('auth/setUser', user)
    }

    async setAuthUser () {
        const token = localStorage.getItem(this.tokenTitle)
        const user = JSON.parse(localStorage.getItem(this.userTitle))
        this.setAuthorizationHeader(token)
        await this.setAuthUserToStore(user)
    }

    async clearAuthData () {
        await this.setAuthorizationHeader()
        await localStorage.clear()
    }
}

<template>
    <div class="text-center mb-auto mt-auto overflow-hidden">
        <h2 :class="fontSize">
            <template v-if="result">
                {{ widget.params.show_symbol ? result.symbol : '' }} {{ result.profit }}
            </template>
            <i
                v-else
                class="pi pi-spin pi-spinner"
            ></i>
        </h2>
    </div>
</template>

<script>

import { computed, reactive } from 'vue'

export default {
    name: 'FuturesProfitActionNumberWidgetLayout',
    props: {
        demo: {
            type: Boolean,
            default: false
        },
        widget: {
            required: true
        }
    },
    setup (props) {
        const demoResult = reactive({
            symbol: 'USDT',
            profit: '00.00'
        })
        const result = props.demo ? demoResult : computed(() => props.widget.result)
        const fontSizes = ['text-xs', 'text-sm', 'text-base', 'text-lg', 'text-xl', 'text-2xl', 'text-3xl', 'text-4xl', 'text-5xl', 'text-6xl', 'text-7xl', 'text-8xl', 'text-9xl']

        const fontSize = computed(() => fontSizes.at((props.widget.params.font_size || 10) - 1))

        return {
            result,
            fontSize
        }
    }
}
</script>

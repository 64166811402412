<template>
    <div class="widget-layout overflow-hidden flex justify-content-center align-items-center">
        <div v-if="showControls" class="controls">
            <Button
                :loading="loadingDelete"
                icon="pi pi-trash"
                class="grabBlock p-button-text p-button-danger p-button-sm"
                @click="$emit('delete-widget', $event, widget.id)"/>
            <Button
                icon="pi pi-cog"
                :loading="loadingEdit"
                class="grabBlock p-button-text p-button-success p-button-sm"
                @click="$emit('edit-widget', $event, widget.id)"/>
            <Button
                v-if="isDraggable"
                icon="pi pi-align-justify"
                class="p-button-text p-button-sm grabHandler"/>
        </div>
        <component
            class="grabBlock"
            :demo="demo"
            :preview="preview"
            :key="widget.id"
            :is="`${widget.widget_type.class_name}WidgetLayout`"
            :widget="widget"/>
    </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
    name: 'WidgetLayout',
    emits: ['edit-widget', 'delete-widget'],
    props: {
        demo: {
            type: Boolean,
            default: false
        },
        preview: {
            type: Boolean,
            default: false
        },
        widget: {
            required: true
        },
        showControls: {
            type: Boolean,
            default: true
        },
        isDraggable: {
            type: Boolean,
            default: true
        }
    },
    setup (props) {
        const store = useStore()
        const loadingEdit = computed(() => store.getters['widget/lastShowWidgetId'] === props.widget.id && store.state.widget.showWidgetLoading)
        const loadingDelete = computed(() => store.getters['widget/lastDeleteWidgetId'] === props.widget.id && store.state.widget.deleteWidgetLoading)

        return {
            loadingDelete,
            loadingEdit
        }
    }
}
</script>

<style lang="scss">
.widget-layout {
    height: 100%;

    .p-card {
        height: 100%;
    }

    .controls {
        opacity: 0.10;
        transition: all 250ms;
        position: absolute;
        top: 1px;
        right: 0;
        text-align: right;

        .p-button {
            &.grabHandler {
                cursor: grab;

                &:focus {
                    box-shadow: none
                }

                &:enabled:active {
                    cursor: grabbing
                }
            }
        }

        &:hover {
            opacity: 1;
        }
    }
}
</style>

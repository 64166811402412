<template>
    <Chart
        v-if="chartResult"
        :type="chartType"
        :data="chartData"
        :options="chartOptions"/>
    <i v-else class="pi pi-spin pi-spinner"/>
</template>

<script>

import { computed, getCurrentInstance, watch, reactive } from 'vue'
import ApiKeyService from '../../../../../services/ApiKeyService'

export default {
    name: 'FuturesProfitActionGraphWidgetLayout',
    props: {
        demo: {
            type: Boolean,
            default: false
        },
        preview: {
            type: Boolean,
            default: false
        },
        widget: {
            required: true
        }
    },
    setup (props) {
        const apiKeyService = new ApiKeyService()
        const app = getCurrentInstance()
        const $moment = app.appContext.config.globalProperties.$moment
        const defaultColor = apiKeyService.defaultColor()

        const chartResult = computed(() => props.demo || (!!props.widget.result || props.preview))

        const generateLabels = (data) => {
            if (data.widget.params && data.widget.params.dates) {
                switch (data.widget.params.dates.last_days) {
                    case ('day') :
                    case ('last_day') :
                        return Array(24).fill().map((val, index) => index + ':00')
                    case ('week') :
                    case ('last_week') :
                        return $moment.weekdays(true)
                    case ('month') :
                        return Array($moment().daysInMonth()).fill().map((val, index) => index + 1)
                    case ('last_month') :
                        return Array($moment().subtract(1, 'month').daysInMonth()).fill().map((val, index) => index + 1)
                    case ('quarter') :
                        return Array(3).fill().map((val, index) => $moment.months()[$moment().quarter() * 3 - 3 + index])
                    case ('last_quarter') :
                        return Array(3).fill().map((val, index) => $moment.months()[$moment().subtract(1, 'quarter').quarter() * 3 - 3 + index])
                    default :
                        return $moment.months()
                }
            } else {
                return $moment.months()
            }
        }
        const demoResult = (data) => {
            const labels = generateLabels(data)
            return {
                labels: labels,
                datasets: data.widget.params.overall_result ? [
                    {
                        label: 'Все ключи',
                        borderWidth: 1,
                        fill: data.widget.params.chart ? !!data.widget.params.chart.fill : false,
                        tension: data.widget.params.chart && data.widget.params.chart.tension ? 0.4 : 0.1,
                        borderColor: defaultColor,
                        backgroundColor: defaultColor + '80',
                        data: Array(labels.length).fill().map(() => Math.round(Math.random() * 100))

                    }
                ] : data.widget.api_keys && data.widget.api_keys.length ? data.widget.api_keys.map(item => {
                    const apiKey = Object.prototype.hasOwnProperty.call(item, 'color') ? item : data.widget.api_keys_models.find(model => model.id === item)
                    return {
                        label: apiKey.note,
                        borderWidth: 1,
                        fill: data.widget.params.chart ? !!data.widget.params.chart.fill : false,
                        tension: data.widget.params.chart && data.widget.params.chart.tension ? 0.4 : 0.1,
                        borderColor: apiKey.color ? apiKey.color : defaultColor,
                        backgroundColor: apiKey.color ? apiKey.color + '80' : defaultColor + '80',
                        data: Array(labels.length).fill().map(() => Math.round(Math.random() * 100))
                    }
                }) : [
                    {
                        label: '#1 Демо ключ',
                        borderWidth: 1,
                        fill: data.widget.params.chart ? !!data.widget.params.chart.fill : false,
                        tension: data.widget.params.chart && data.widget.params.chart.tension ? 0.4 : 0.1,
                        borderColor: '#fff',
                        backgroundColor: '#ffffff80',
                        data: Array(labels.length).fill().map(() => Math.round(Math.random() * 100))

                    },
                    {
                        label: '#2 Демо ключ',
                        borderWidth: 1,
                        fill: data.widget.params.chart ? !!data.widget.params.chart.fill : false,
                        tension: data.widget.params.chart && data.widget.params.chart.tension ? 0.4 : 0.1,
                        borderColor: defaultColor,
                        backgroundColor: defaultColor + '80',
                        data: Array(labels.length).fill().map(() => Math.round(Math.random() * 100))

                    },
                    {
                        label: '#3 Демо ключ',
                        borderWidth: 1,
                        fill: data.widget.params.chart ? !!data.widget.params.chart.fill : false,
                        tension: data.widget.params.chart && data.widget.params.chart.tension ? 0.4 : 0.1,
                        borderColor: '#666',
                        backgroundColor: '#66666680',
                        data: Array(labels.length).fill().map(() => Math.round(Math.random() * 100))

                    }
                ]
            }
        }
        const checkData = (data) => {
            return !data.demo && data.widget.result && data.widget.result.datasets instanceof Array ? {
                labels: generateLabels(data),
                datasets: data.widget.result.datasets.map(dataset => {
                    return {
                        label: dataset.label,
                        borderWidth: 1,
                        fill: data.widget.params.chart ? !!data.widget.params.chart.fill : false,
                        tension: data.widget.params.chart && data.widget.params.chart.tension ? 0.4 : 0.1,
                        borderColor: dataset.color ? dataset.color : defaultColor,
                        backgroundColor: dataset.color ? dataset.color + '80' : defaultColor + '80',
                        data: dataset.data
                    }
                })
            } : demoResult(data)
        }
        const checkOptions = (data) => {
            return {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: data.widget.params.chart ? !!data.widget.params.chart.legend : false
                    },
                    tooltip: {
                        callbacks: {
                            title: function (context) {
                                return context[0].label + ' ' + (context[0].dataset.label ?? '')
                            },

                            label: function (context) {
                                return (data.widget.result ? data.widget.result.symbol : 'USDT') + ' ' + context.parsed.y
                            }
                        }
                    }
                },
                scales: {
                    y: {
                        ...data.widget.params.chart && data.widget.params.chart.axis_y ? {
                            grid: {
                                color: (line) => (line.tick.value === 0 ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.1)')
                            }
                        } : null,
                        display: data.widget.params.chart ? data.widget.params.chart.scale_y : true
                    },
                    x: {
                        display: data.widget.params.chart ? data.widget.params.chart.scale_x : true
                    }
                }
            }
        }
        const chartType = computed(() => props.widget.params.chart ? props.widget.params.chart.type : 'bar')
        const chartData = reactive(checkData(props))
        const chartOptions = reactive(checkOptions(props))
        watch(props, (state, prevState) => {
            Object.assign(chartData, checkData(state))
            Object.assign(chartOptions, checkOptions(state))
        })

        return {
            chartResult,
            chartData,
            chartOptions,
            chartType
        }
    }
}
</script>

import vuexStore from '../../core/vuexStore'

export const { state, getters, mutations, actions, namespaced } = vuexStore({
    routes: {
        getSpotOrders: {
            isLoading: true,
            isFilter: true,
            isSort: true,
            isPagination: true,
            isUpdateWithReplace: true,
            isTotalCount: true,
            endpoint: '/user/orders/spot'
        },
        getMarginOrders: {
            isLoading: true,
            isFilter: true,
            isSort: true,
            isPagination: true,
            isUpdateWithReplace: true,
            isTotalCount: true,
            endpoint: '/user/orders/margin'
        },
        getFuturesOrders: {
            isLoading: true,
            isFilter: true,
            isSort: true,
            isPagination: true,
            isUpdateWithReplace: true,
            isTotalCount: true,
            endpoint: '/user/orders/futures'
        }
    },
    mutations: {
        SET_TRADES: (state, payload) => {
            if (payload && payload.stateName && payload.orderId && state[payload.stateName]) {
                const index = state[payload.stateName].findIndex(order => order.id === payload.orderId)
                state[payload.stateName][index].trades = payload.trades
            }
        }
    },
    actions: {
        setTrades: async (context, payload) => {
            const { stateName, orderId, trades } = payload
            context.commit('SET_TRADES', { stateName, orderId, trades })
        }
    }
})

import { store } from '../store'

export default class UserService {
    async getProfile (fields) {
        await store.dispatch('user/getProfile', {
            _data: fields
        })
    }

    async updateProfile (fields) {
        await store.dispatch('user/updateProfile', {
            _data: fields
        })
    }

    async changePassword (fields) {
        await store.dispatch('user/changePassword', {
            _data: fields
        })
    }

    async updateSettings (fields) {
        await store.dispatch('user/updateSettings', {
            _data: fields
        })
    }

    async updateGetProfileSettings (payload) {
        await store.dispatch('user/updateGetProfileSettings', payload)
    }

    async updateUserSpotTotalBalance (payload) {
        await store.dispatch('user/updateUserSpotTotalBalance', payload)
    }

    async updateUserFuturesTotalBalance (payload) {
        await store.dispatch('user/updateUserFuturesTotalBalance', payload)
    }
}

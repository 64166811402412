import { store } from '../store'

export default class BoardService {
    async getBoards (params) {
        return await store.dispatch('board/getBoards', {
            _data: params
        })
    }

    async showBoard (id) {
        return await store.dispatch('board/showBoard', {
            _params: {
                boardId: id
            }
        })
    }

    async editBoard (id) {
        return await store.dispatch('board/editBoard', {
            _params: {
                boardId: id
            }
        })
    }

    async updateBoard (id, data) {
        return await store.dispatch('board/updateBoard', {
            _params: {
                boardId: id
            },
            _data: data
        })
    }

    async storeBoard (data) {
        return await store.dispatch('board/storeBoard', {
            _data: data
        })
    }

    async deleteBoard (id) {
        return await store.dispatch('board/deleteBoard', {
            _params: {
                boardId: id
            }
        })
    }

    async modalToggle (state) {
        return await store.dispatch('board/setModalState', state)
    }

    boardsLimit () {
        return process.env.VUE_APP_BOARDS_LIMIT_PER_USER ?? 10
    }
}

<template>
    <div class="grid">
        <div class="col-6">
            <div class="field">
                <label for="widgetDatesLastDays">Выбрать период</label>
                <Dropdown
                    id="widgetDatesLastDays"
                    :modelValue="lastDays"
                    @update:modelValue="$emit('update-params-with-result', {dates: {last_days:$event}})"
                    :options="lastDaysOptions"
                    optionLabel="name"
                    optionValue="slug"
                    placeholder="Выбрать"
                    :area-invalid="lastDaysError ? true : null"
                    :aria-describedby="lastDaysError ? lastDaysError : null"
                    :class="{ 'p-invalid' : lastDaysError}"/>
                <div v-if="lastDaysError" class="p-error text-left">
                    <small>{{ lastDaysError }}</small></div>
            </div>
        </div>
        <div class="col-6">
            <div class="field">
                <div class="label mb-2">Суммировать ключи</div>
                <div class="flex align-items-center py-1">
                    <InputSwitch
                        id="widgetOverallResult"
                        :modelValue="overallResult"
                        @update:modelValue="$emit('update-params-with-result', {overall_result: $event})"
                        :area-invalid="overallResultError ? true : null"
                        :aria-describedby="overallResultError ? overallResultError : null"
                        :class="[{'p-invalid' : overallResultError}, 'mt-auto']"/>
                </div>
                <div v-if="overallResultError" class="p-error text-left">
                    <small>{{ overallResultError }}</small>
                </div>
            </div>
        </div>
    </div>
    <div class="field">
        <label for="widgetChartType">Выбрать тип графика</label>
        <Dropdown
            id="widgetChartType"
            :modelValue="chartType"
            @update:modelValue="$emit('update-params', {chart: {type:$event}})"
            :options="chartTypeOptions"
            optionLabel="name"
            optionValue="slug"
            placeholder="Выбрать"
            :area-invalid="chartTypeError ? true : null"
            :aria-describedby="chartTypeError ? chartTypeError : null"
            :class="{ 'p-invalid' : chartTypeError}"/>
        <div v-if="chartTypeError" class="p-error text-left">
            <small>{{ chartTypeError }}</small>
        </div>
    </div>
    <div v-if="chartType === 'line'" class="grid">
        <div class="col-6">
            <div class="field">
                <div class="label mb-2">Заливка</div>
                <div class="flex align-items-center py-1">
                    <InputSwitch
                        id="widgetChartFill"
                        :modelValue="chartFill"
                        @update:modelValue="$emit('update-params', {chart: {fill:$event}})"
                        :area-invalid="chartFillError ? true : null"
                        :aria-describedby="chartFillError ? chartFillError : null"
                        :class="[{'p-invalid' : chartFillError}, 'mt-auto']"/>
                </div>
                <div v-if="chartFillError" class="p-error text-left">
                    <small>{{ chartFillError }}</small>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="field">
                <div class="label mb-2">Смягчить края</div>
                <div class="flex align-items-center py-1">
                    <InputSwitch
                        id="widgetChartTension"
                        :modelValue="chartTension"
                        @update:modelValue="$emit('update-params', {chart: {tension:$event}})"
                        :area-invalid="chartTensionError ? true : null"
                        :aria-describedby="chartTensionError ? chartTensionError : null"
                        :class="[{'p-invalid' : chartTensionError}, 'mt-auto']"/>
                </div>
                <div v-if="chartTensionError" class="p-error text-left">
                    <small>{{ chartTensionError }}</small>
                </div>
            </div>
        </div>
    </div>
    <div class="grid">
        <div class="col-6">
            <div class="field">
                <div class="label mb-2">Нулевая граница</div>
                <div class="flex align-items-center py-1">
                    <InputSwitch
                        id="widgetChartAxisY"
                        :modelValue="chartAxisY"
                        @update:modelValue="$emit('update-params', {chart: {axis_y:$event}})"
                        :area-invalid="chartAxisYError ? true : null"
                        :aria-describedby="chartAxisYError ? chartAxisYError : null"
                        :class="[{'p-invalid' : chartAxisYError}, 'mt-auto']"/>
                </div>
                <div v-if="chartAxisYError" class="p-error text-left">
                    <small>{{ chartAxisYError }}</small>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="field">
                <div class="label mb-2">Легенда</div>
                <div class="flex align-items-center py-1">
                    <InputSwitch
                        id="widgetChartLegend"
                        :modelValue="chartLegend"
                        @update:modelValue="$emit('update-params', {chart: {legend:$event}})"
                        :area-invalid="chartLegendError ? true : null"
                        :aria-describedby="chartLegendError ? chartLegendError : null"
                        :class="[{'p-invalid' : chartLegendError}, 'mt-auto']"/>
                </div>
                <div v-if="chartLegendError" class="p-error text-left">
                    <small>{{ chartLegendError }}</small>
                </div>
            </div>
        </div>
    </div>
    <div class="grid">
        <div class="col-6">
            <div class="field">
                <div class="label mb-2">Ось Y</div>
                <div class="flex align-items-center py-1">
                    <InputSwitch
                        id="widgetChartScaleY"
                        :modelValue="chartScaleY"
                        @update:modelValue="$emit('update-params', {chart: {scale_y:$event}})"
                        :area-invalid="chartScaleYError ? true : null"
                        :aria-describedby="chartScaleYError ? chartScaleYError : null"
                        :class="[{'p-invalid' : chartScaleYError}, 'mt-auto']"/>
                </div>
                <div v-if="chartScaleYError" class="p-error text-left">
                    <small>{{ chartScaleYError }}</small>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="field">
                <div class="label mb-2">Ось X</div>
                <div class="flex align-items-center py-1">
                    <InputSwitch
                        id="widgetChartScaleX"
                        :modelValue="chartScaleX"
                        @update:modelValue="$emit('update-params', {chart: {scale_x:$event}})"
                        :area-invalid="chartScaleXError ? true : null"
                        :aria-describedby="chartScaleXError ? chartScaleXError : null"
                        :class="[{'p-invalid' : chartScaleXError}, 'mt-auto']"/>
                </div>
                <div v-if="chartScaleXError" class="p-error text-left">
                    <small>{{ chartScaleXError }}</small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { computed, getCurrentInstance } from 'vue'

export default {
    name: 'SpotBalanceActionGraphWidgetParams',
    emits: ['update-params', 'update-params-with-result'],
    props: {
        demo: {
            type: Boolean,
            default: false
        },
        params: {
            required: true
        },
        errors: {
            required: true
        }
    },
    setup (props) {
        const app = getCurrentInstance()
        if (props.demo) {
            app.emit('update-params', {
                overall_result: true,
                dates: {
                    last_days: 'day'
                },
                chart: {
                    type: 'bar',
                    legend: true,
                    axis_y: true,
                    tension: true,
                    fill: true,
                    scale_x: true,
                    scale_y: true
                },
                position: {
                    x: 0,
                    y: 0,
                    h: 8,
                    w: 12
                }
            })
        }
        const overallResult = computed(() => props.params.overall_result)
        const lastDays = computed(() => props.params.dates ? props.params.dates.last_days : null)
        const chartType = computed(() => props.params.chart ? props.params.chart.type : 'bar')
        const chartScaleY = computed(() => props.params.chart ? props.params.chart.scale_y : true)
        const chartScaleX = computed(() => props.params.chart ? props.params.chart.scale_x : true)
        const chartLegend = computed(() => props.params.chart ? props.params.chart.legend : true)
        const chartAxisY = computed(() => props.params.chart ? props.params.chart.axis_y : true)
        const chartTension = computed(() => props.params.chart ? props.params.chart.tension : true)
        const chartFill = computed(() => props.params.chart ? props.params.chart.fill : true)

        const overallResultError = computed(() => props.errors['params.overall_result'] ? props.errors['params.overall_result'] : null)
        const lastDaysError = computed(() => props.errors['params.dates.last_days'] ? props.errors['params.dates.last_days'] : null)
        const chartTypeError = computed(() => props.errors['params.chart.type'] ? props.errors['params.chart.type'] : null)
        const chartScaleYError = computed(() => props.errors['params.chart.scale_y'] ? props.errors['params.chart.scale_y'] : null)
        const chartScaleXError = computed(() => props.errors['params.chart.scale_x'] ? props.errors['params.chart.scale_x'] : null)

        const chartLegendError = computed(() => props.errors['params.chart.legend'] ? props.errors['params.chart.legend'] : null)
        const chartAxisYError = computed(() => props.errors['params.chart.axis_y'] ? props.errors['params.chart.axis_y'] : null)
        const chartTensionError = computed(() => props.errors['params.chart.tension'] ? props.errors['params.chart.tension'] : null)
        const chartFillError = computed(() => props.errors['params.chart.fill'] ? props.errors['params.chart.fill'] : null)

        const chartTypeOptions = [
            {
                name: 'Столбиковая диаграмма',
                slug: 'bar'
            },
            {
                name: 'Линейный график',
                slug: 'line'
            }
        ]
        const lastDaysOptions = [
            {
                name: 'За сегодня',
                slug: 'day'
            },
            {
                name: 'За вчера',
                slug: 'last_day'
            },
            {
                name: 'За неделю',
                slug: 'week'
            },
            {
                name: 'За прошлую неделю',
                slug: 'last_week'
            },
            {
                name: 'За месяц',
                slug: 'month'
            },
            {
                name: 'За прошлый месяц',
                slug: 'last_month'
            },
            {
                name: 'За квартал',
                slug: 'quarter'
            },
            {
                name: 'За прошлый квартал',
                slug: 'last_quarter'
            },
            {
                name: 'За год',
                slug: 'year'
            },
            {
                name: 'За прошлый год',
                slug: 'last_year'
            }
        ]

        return {
            lastDays,
            overallResult,
            lastDaysOptions,
            chartType,
            chartTypeError,
            chartTypeOptions,
            lastDaysError,
            chartScaleY,
            chartScaleX,
            overallResultError,
            chartScaleYError,
            chartScaleXError,
            chartFill,
            chartFillError,
            chartAxisY,
            chartAxisYError,
            chartLegend,
            chartLegendError,
            chartTension,
            chartTensionError
        }
    }
}
</script>

<template>
    <Dialog
        :visible="visible"
        @update:visible="closeModal"
        :modal="true"
        :breakpoints="{'960px': '75vw', '640px': '100vw'}"
        :style="{width: '450px'}"
        :header="isModalStateCreate ? 'Новый отчет' : 'Редактировать отчет'"
        class="p-fluid">
        <div class="field">
            <label for="boardTitle">Заголовок для отчета</label>
            <InputText v-model="boardForm.fields.title" id="boardTitle"
                       :area-invalid="boardForm.errors.title ? true : null"
                       :aria-describedby="boardForm.errors.title ? boardForm.errors.title : null"
                       :class="{ 'p-invalid' : boardForm.errors.title }"/>
            <div v-if="boardForm.errors.title" class="p-error text-left">
                <small>{{ boardForm.errors.title }}</small></div>
        </div>
        <InlineMessage
            v-if="boardForm.errors.board"
            severity="warn">
            {{ boardForm.errors.board }}
        </InlineMessage>
        <template #footer>
            <Button
                label="Отмена"
                icon="pi pi-times"
                class="p-button-text"
                @click="closeModal"
            />
            <Button
                v-if="isModalStateEdit"
                label="Сохранить"
                icon="pi pi-check"
                class="p-button-text"
                :loading="loading"
                @click="updateBoard"
            />
            <Button
                v-else-if="isModalStateCreate"
                label="Добавить"
                icon="pi pi-plus"
                class="p-button-text"
                :loading="loading"
                @click="storeBoard"
            />
        </template>
    </Dialog>
</template>

<script>
import { reactive, computed, watch } from 'vue'
import { useStore } from 'vuex'
import ToastMessageService from '../../services/ToastMessageService'
import FormService from '../../services/FormService'
import BoardService from '../../services/BoardService'
import router from '../../router'

export default {
    name: 'BoardModal',
    setup () {
        const store = useStore()
        const boardService = new BoardService()
        const formService = new FormService()
        const toastMessageService = new ToastMessageService()
        const loading = computed(() => store.state.board.storeBoardLoading || store.state.board.updateBoardLoading)
        const boards = computed(() => store.state.board.getBoards)
        const visible = computed(() => store.getters['board/isModalVisible'])
        const editBoard = computed(() => store.state.board.editBoard)
        const isModalStateEdit = computed(() => store.getters['board/isModalStateEdit'])
        const isModalStateCreate = computed(() => store.getters['board/isModalStateCreate'])
        const defaultBoardForm = () => {
            return {
                fields: {
                    title: null,
                    id: null
                },
                errors: {
                    title: null,
                    board: null
                }
            }
        }
        const boardForm = reactive(defaultBoardForm())
        watch(editBoard, (state, prevState) => {
            Object.assign(boardForm.fields, state)
        })

        const updateBoard = async () => {
            try {
                await boardService.updateBoard(boardForm.fields.id, boardForm.fields)
                await toastMessageService.add({
                    detail: 'Отчет сохранен'
                })
                const boardId = boardForm.fields.id
                closeModal()
                await Promise.all([boardService.getBoards(), boardService.showBoard(boardId)])
            } catch (exception) {
                formService.fillErrors(exception, boardForm)
            }
        }

        const storeBoard = async () => {
            try {
                await boardService.storeBoard(boardForm.fields)
                await toastMessageService.add({
                    detail: 'Отчет добавлен'
                })
                closeModal()
                await boardService.getBoards()
                await router.push({ name: 'board', params: { boardId: boards.value[0].id } })
            } catch (exception) {
                formService.fillErrors(exception, boardForm)
            }
        }

        const closeModal = () => {
            Object.assign(boardForm, defaultBoardForm())
            boardService.modalToggle(false)
        }

        return {
            loading,
            boardForm,
            storeBoard,
            updateBoard,
            visible,
            isModalStateEdit,
            isModalStateCreate,
            editBoard,
            closeModal
        }
    }
}
</script>

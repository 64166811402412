import vuexStore from '../../core/vuexStore'

export const { state, getters, mutations, actions, namespaced } = vuexStore({
    routes: {
        getWidgets: {
            isLoading: true,
            isUpdateWithReplace: true,
            baseActions: ['deleteItem', 'updateItem', 'updateDeepItem'],
            endpoint: '/user/boards/{boardId}/widgets'
        },
        resetWidgets: {
            stateName: 'getWidgets'
        },
        showWidget: {
            isLoading: true,
            isSaveLastRequest: true,
            endpoint: '/user/boards/{boardId}/widgets/{widgetId}'
        },
        updateWidget: {
            isLoading: true,
            method: 'put',
            endpoint: '/user/boards/{boardId}/widgets/{widgetId}'
        },
        updatePosition: {
            method: 'put',
            endpoint: '/user/boards/{boardId}/widgets/{widgetId}/position'
        },
        storeWidget: {
            isLoading: true,
            method: 'post',
            endpoint: '/user/boards/{boardId}/widgets'
        },
        deleteWidget: {
            isLoading: true,
            method: 'delete',
            isSaveLastRequest: true,
            endpoint: '/user/boards/{boardId}/widgets/{widgetId}'
        }
    },
    states: {
        modalState: false
    },
    getters: {
        isModalVisible: state => !!state.modalState,
        isModalStateEdit: state => state.modalState === 'edit',
        isModalStateCreate: state => state.modalState === 'create',
        lastShowWidgetId: state => state.showWidgetLastRequest ? state.showWidgetLastRequest._params.widgetId : null,
        lastDeleteWidgetId: state => state.deleteWidgetLastRequest ? state.deleteWidgetLastRequest._params.widgetId : null,
        getWidgetsLayout: state => {
            return state.getWidgets ? state.getWidgets.map(item => {
                return {
                    ...item,
                    ...{
                        x: item.params.position.x,
                        y: item.params.position.y,
                        w: item.params.position.w,
                        h: item.params.position.h,
                        i: item.id,
                        key: item.id
                    }
                }
            }) : null
        }
    },
    mutations: {
        SET_MODAL_STATE: (state, payload) => {
            state.modalState = payload
        }
    },
    actions: {
        setModalState: async (context, payload) => {
            context.commit('SET_MODAL_STATE', payload)
        }
    }
})

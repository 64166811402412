import { createRouter, createWebHistory } from 'vue-router'
import auth from './middleware/auth'
import guest from './middleware/guest'
import { store } from '../store'
import middlewarePipeline from './middlewarePipeline'
import App from '../app/App.vue'
import AppGuest from '../app/AppGuest.vue'

const routes = [
    {
        path: '/',
        name: 'addNewBoard',
        exact: true,
        component: () => import('../components/AddNewBoard.vue'),
        meta: {
            breadcrumb: [{ parent: 'Dashboard', label: 'Мои отчеты' }],
            layout: App,
            middleware: [
                auth
            ]
        }
    },
    {
        path: '/api-keys',
        name: 'api-keys',
        exact: true,
        component: () => import('../components/ApiKeys.vue'),
        meta: {
            breadcrumb: [{ parent: 'Дата', label: 'API ключи' }],
            layout: App,
            middleware: [
                auth
            ]
        }
    },
    {
        path: '/orders',
        name: 'orders',
        exact: true,
        component: () => import('../components/Orders.vue'),
        meta: {
            breadcrumb: [{ parent: 'Ифнормация', label: 'Сделки' }],
            layout: App,
            middleware: [
                auth
            ]
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('../components/Profile.vue'),
        meta: {
            breadcrumb: [{ label: 'Профиль' }],
            layout: App,
            middleware: [
                auth
            ]
        }
    },
    {
        path: '/boards/:boardId',
        name: 'board',
        component: () => import('../pages/Board.vue'),
        props: true,
        meta: {
            layout: App,
            middleware: [
                auth
            ]
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../pages/Login.vue'),
        meta: {
            layout: AppGuest,
            middleware: [
                guest
            ]
        }
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../pages/Register.vue'),
        meta: {
            layout: AppGuest,
            middleware: [
                guest
            ]
        }
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('../pages/ForgotPassword.vue'),
        meta: {
            layout: AppGuest,
            middleware: [
                guest
            ]
        }
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import('../pages/ResetPassword.vue'),
        props: (route) => ({
            token: route.query.token,
            email: route.query.email
        }),
        meta: {
            layout: AppGuest,
            middleware: [
                guest
            ]
        }
    },
    {
        path: '/404',
        name: '404',
        component: () => import('../pages/NotFound.vue'),
        meta: {
            layout: AppGuest
        }
    },
    {
        path: '/401',
        name: '401',
        component: () => import('../pages/Unauthorized.vue'),
        meta: {
            layout: AppGuest
        }
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/404',
        meta: {
            layout: AppGuest
        }
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware

    const context = {
        to,
        from,
        next,
        store
    }

    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })
})

export default router
